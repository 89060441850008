import MainHeader from './MainHeader'
import SubHeader from './SubHeader'

const Header = ({ refetchListings }) => {
  return (
    <>
      <MainHeader />
      <SubHeader refetchListings={refetchListings} />
    </>
  )
}

export default Header

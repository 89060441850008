import { Skeleton } from '@mui/material'
import useAxios from 'axios-hooks'
import { Link } from 'react-router-dom'

import { LinksContainer, Neighborhood, NotFoundContainer, NotFoundHeader } from './NotFound.style'
import { PUBLIC_BUILDING_NAMES } from '../../../constants/api'
import { NOTFOUND_DEFAULT } from '../../../constants/image.const'

const NotFound = () => {
  const pathArray = window.location.pathname.split('/').filter(Boolean)
  const buildingSearch = pathArray[1]

  const [{ data: buildingsResponse, loading: loadingBuildingResponse }] = useAxios({
    url: PUBLIC_BUILDING_NAMES,
    params: {
      size: 5,
      // name__icontains: searchValue,
      name: buildingSearch,
      // ordering: '-number_of_units',
    },
  })

  const linkLoaders = Array.from({ length: 5 }, (_, i) => <Skeleton key={i} height={20} width={200} />)

  const buildingLinks = buildingsResponse?.results?.map((building) => (
    <div key={building?.uuid} onClick={() => (window.location = building?.url)}>
      <Link to={building?.url}>{building?.name}</Link> <Neighborhood> in {building?.neighborhood?.name}</Neighborhood>
    </div>
  ))

  return (
    <NotFoundContainer>
      <NotFoundHeader>
        <h1>Not Found</h1>
        <p>We can't find this building, perhaps you meant to search for one of these instead?</p>
        <LinksContainer>{loadingBuildingResponse ? linkLoaders : buildingLinks}</LinksContainer>
      </NotFoundHeader>
      <img src={NOTFOUND_DEFAULT} alt="building-default" />
    </NotFoundContainer>
  )
}

export default NotFound

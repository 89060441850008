import { AdsContainer, SponsorTag } from './Ads.style'
import GoogleAd from './GoogleAd'

const Ads = ({ variant, type }) => (
  <AdsContainer variant={variant} id={`ad-slot-id-${variant}-${Math.random()}`}>
    <GoogleAd type={type} />
    <SponsorTag variant={variant}>SPONSORED AD</SponsorTag>
  </AdsContainer>
)

export default Ads

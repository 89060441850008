import { memo } from 'react'

import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

import { HeartIconButton, SaveSearchButtonContainer } from './SaveSearchButton.style'
import { HEART_OUTLINE, HEART_SOLID } from '../../../../constants/image.const'
import { modalsActionReducer } from '../../../../store/modals/modalsSlice'

const SaveSearchButton = ({ isLoading }) => {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state?.auth)
  const { neighborhoods, savedSearch } = useSelector((state) => state?.search)
  const { bedsContainer, priceContainer, amenitiesContainer, moreContainer } = useSelector((state) => state.search)

  const openLoginModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const openSavedSearchModal = () => dispatch(modalsActionReducer.setSavedSearchModalIsOpen(true))

  const userIsLoggedIn = !!user?.user
  const isASavedSearch = userIsLoggedIn && !!savedSearch

  const neighborhoodsAreFiltered = !!neighborhoods?.length
  const bedsAreFiltered = bedsContainer !== 'Any'
  const priceIsFiltered = priceContainer !== 'Any'
  const amenitiesAreFiltered = amenitiesContainer !== 'Select Amenities'
  const moreOptionsAreFiltered = moreContainer !== 'Browse Other Options'

  const savedSearchButtonIsEnabled =
    neighborhoodsAreFiltered || bedsAreFiltered || priceIsFiltered || amenitiesAreFiltered || moreOptionsAreFiltered

  const handleSaveSearchButtonOnClick = () => {
    if (!userIsLoggedIn) return openLoginModal()
    openSavedSearchModal()
    ReactGA.event({
      category: 'action',
      action: 'search-page-save-search-button-clicked',
    })
  }

  return (
    <SaveSearchButtonContainer>
      <HeartIconButton onClick={handleSaveSearchButtonOnClick} disabled={!savedSearchButtonIsEnabled}>
        {isLoading ? (
          <Loader size="small" active color="white" inverted />
        ) : (
          <img src={isASavedSearch ? HEART_SOLID : HEART_OUTLINE} alt="Save Search" />
        )}
      </HeartIconButton>
    </SaveSearchButtonContainer>
  )
}

export default memo(SaveSearchButton)

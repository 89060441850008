import styled from 'styled-components'

export const BackgroundContainer = styled.div.attrs({
  className: 'BackgroundContainer',
})`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;

  @media (max-width: 992px) {
    background-color: rgba(0, 0, 0, 0.9); /* Slightly darker for full-screen mobile experience */
    justify-content: flex-start; /* Align modal to the top */
    padding: 0;
  }
`

export const CloseIconContainer = styled.svg.attrs({
  className: 'CloseIconContainer',
})`
  user-select: none;
  cursor: pointer;

  &:hover {
    > path {
      fill: #b7b7b7;
    }
  }

  position: absolute;
  right: 12px;
  top: 12px;

  @media (max-width: 992px) {
    right: 8px; /* Adjust positioning for smaller screens */
    top: 8px;
  }
`

export const ModalContainer = styled.div.attrs({
  className: 'ModalContainer',
})`
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 50%; /* Default width */
  background: white;

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
`

import styled from 'styled-components'

import THEME from '../../constants/themes'

export const AccountContainer = styled.div.attrs({
  className: 'AccountContainer',
})`
  min-height: calc(100vh - 140px);
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 70px;
  background: ${THEME.backgroundGray};
  left: 0px;
  width: 100%;
  @media (max-width: 992px) {
    min-height: auto;
  }
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 140px;
  z-index: 200;
  width: 100%;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
  background: white;
  @media (max-width: 992px) {
    top: 70px;
    border: none;
  }
`

export const TabContentContainer = styled.div.attrs({
  className: 'TabContentContainer',
})`
  // padding: 10px;
  height: 500px;
`

export const TabContainer = styled.div.attrs({
  className: 'TabContainer',
})(
  ({ isActive }) => `
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;    
    height: 100%;
    width: 100px;
    padding: 0 1em;
    z-index: 100;
    position: relative;
    background: ${isActive ? THEME.backgroundGray : 'white'};
    > a { 
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: #333;
    }
    &:hover {
      background: ${THEME.backgroundGray};
    }
    &:after {
      content: '';
      height: 80%;
      position: absolute;
      right: 0;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
    &:first-child {
      padding-left: 60px;
      width: 170px;
    }
    @media (max-width: 992px) {
      transition: none;
      font-size: 12px;
      background: ${isActive ? THEME.mediumBlue : THEME.darkBlue};
      flex: 1;
      &:first-child {
        width: 100px;
        padding-left: 1em;
      }
      &:after {
        border: none;
      }
      &:hover {
        background: ${isActive ? THEME.mediumBlue : THEME.darkBlue};
      }
      > a {
        color: #eee;
      }      
    }
  `,
)

export const TabControllerContainer = styled.div.attrs({
  className: 'TabControllerContainer',
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;
  width: 570px;
  @media (max-width: 992px) {
    width: 100%;
    justify-content: space-around;
  }
`

import { useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  IconContainer,
  IconsContainer,
  OrderingDropdownContainer,
  OrderingOptionContainer,
  OrderingOptionsContainer,
  OrderingValueContainer,
} from './OrderingDropdown.style'
import useOutsideClick from '../../../../../../hooks/outsideClick'
import { searchActionReducer } from '../../../../../../store/search/searchSlice'
import { DownArrow } from '../../../../Filters/Containers'

const OrderingDropdown = ({ refetchListings }) => {
  const dropdownRef = useRef()

  const dispatch = useDispatch()

  const setOrdering = (payload) => dispatch(searchActionReducer?.setOrdering(payload))

  const { ordering } = useSelector((state) => state?.search)

  const options = [
    { label: 'Newest', value: '-on_market_date' },
    { label: 'Recently Updated', value: '-last_modification_datetime' },
    { label: 'Most Expensive', value: '-price' },
    { label: 'Least Expensive', value: 'price' },
    { label: 'Largest', value: '-unit__square_footage' },
    { label: 'Smallest', value: 'unit__square_footage' },
  ]

  const orderingOption = options?.find((option) => option?.value === ordering)

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const handleDropdownOnClick = () => {
    setDropdownIsOpen((dropdownIsOpen) => !dropdownIsOpen)
  }

  const handleOptionOnClick = (option) => {
    setOrdering(option?.value)
    setDropdownIsOpen(false)
    refetchListings({ ordering: option?.value }, 'OrderingDropdown.handleOptionOnClick')
  }

  useOutsideClick(
    dropdownRef,
    () => {
      setDropdownIsOpen(false)
    },
    [ordering],
  )

  const orderingOptions = options
    ?.filter((option) => option?.value !== ordering)
    ?.map((option) => (
      <OrderingOptionContainer key={option?.value} onClick={() => handleOptionOnClick(option)}>
        {option?.label}
      </OrderingOptionContainer>
    ))

  return (
    <OrderingDropdownContainer ref={dropdownRef} onClick={handleDropdownOnClick}>
      <OrderingValueContainer>
        {orderingOption?.label}
        <IconsContainer>
          <IconContainer>
            <DownArrow />
          </IconContainer>
        </IconsContainer>
      </OrderingValueContainer>
      <OrderingOptionsContainer isOpen={dropdownIsOpen}>{orderingOptions}</OrderingOptionsContainer>
    </OrderingDropdownContainer>
  )
}

export default OrderingDropdown

import MagicSliderDots from 'react-magic-slider-dots'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-magic-slider-dots/dist/magic-dots.css'

import ArrowIcon from './ArrowIcon'
import { Image, ImagesContainer } from './Images.style'

const Images = ({ listing }) => {
  const sortedListingMedias = listing?.medias?.sort((a, b) => a.order - b.order)

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={13} />
    },
    nextArrow: <ArrowIcon type="next" />,
    prevArrow: <ArrowIcon type="previous" />,
  }

  const images = sortedListingMedias?.map((item, index) => (
    <Image
      key={index}
      src={item?.publicImageUrl}
      alt="preview"
      // loading="eager"
      loading={index < 3 ? 'eager' : 'lazy'}
    />
  ))

  return (
    <ImagesContainer>
      <Slider {...settings}>{images}</Slider>
    </ImagesContainer>
  )
}

export default Images

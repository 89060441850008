import styled from 'styled-components'

import THEME from '../../constants/themes'

export const TermsContainer = styled.div.attrs({
  className: 'TermsContainer',
})`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(230, 230, 230);
  margin: 1em auto;
  max-width: 500px;
  border-radius: 20px;
  padding: 1em 2em;
  background: white;
  @media (max-width: 992px) {
    border-radius: none;
    background: ${THEME.backgroundGray};
    border: none;
    font-size: 13px;
  }
`

export const TermsSection = styled.div.attrs({
  className: 'TermsSection',
})`
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TermsParagraph = styled.p.attrs({
  className: 'TermsParagraph',
})`
  text-align: justify;
`

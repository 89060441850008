import { NotFoundContainer, NotFoundHeader } from './NotFound.style'
import { NOTFOUND_DEFAULT } from '../../../constants/image.const'
const NotFound = () => {
  const pathArray = window.location.pathname.split('/').filter(Boolean)
  const buildingLink = '/' + pathArray[0] + '/' + pathArray[1]
  const listingStatus = pathArray[pathArray?.length - 1]
  const statusMessage =
    listingStatus === 'for-sale' ? 'for sale' : listingStatus === 'for-rent' ? 'for rent' : 'available'

  return (
    <NotFoundContainer>
      <NotFoundHeader>
        <h1>Not Found</h1>
        <p>
          It appears that this unit is not currently {statusMessage}. But you can search for other listings in this
          building on the <a href={buildingLink}>building page.</a>
        </p>
      </NotFoundHeader>
      <img src={NOTFOUND_DEFAULT} alt="building-default" />
    </NotFoundContainer>
  )
}

export default NotFound

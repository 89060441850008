import { useState, useEffect } from 'react'

import { Marker } from 'react-leaflet'
import { useDispatch } from 'react-redux'

import MarkerHover from './MarkerHover'
import MarkerPopup from './MarkerPopup'
import { selectionsActionReducer } from '../../../../../../store/selections/selectionsSlice'
import './markers.css'

const BuildingMarker = ({ building, isSelected }) => {
  const dispatch = useDispatch()

  const [popUpIsOpen, setPopUpIsOpen] = useState(false)
  const [hoverIsOpen, setHoverIsOpen] = useState(false)
  const [position, setPosition] = useState([building?.coordinates?.[1], building?.coordinates?.[0]])

  const setSelectedBuildingUuid = (payload) => dispatch(selectionsActionReducer.setBuilding(payload))
  const setLat = (payload) => dispatch(selectionsActionReducer.setLat(payload))
  const setLon = (payload) => dispatch(selectionsActionReducer.setLon(payload))

  const buildingUuid = building?.uuid

  const handleMarkerOnClick = (e) => {
    setPopUpIsOpen(true)
    setHoverIsOpen(false)
    setSelectedBuildingUuid(buildingUuid)
  }

  const handleMarkerOnMouseover = (e) => {
    if (popUpIsOpen) return
    setHoverIsOpen(true)
    setSelectedBuildingUuid(null)
  }

  const handleMarkerOnMouseout = (e) => {
    setHoverIsOpen(false)
  }

  const handleMarkerOnDragEnd = (e) => {
    const coordinates = e?.target?.getLatLng()
    const lat = Number(coordinates?.lat?.toFixed(5))
    const lon = Number(coordinates?.lng?.toFixed(5))
    const newPosition = [lat, lon]
    setPosition(newPosition)
    setLat(lat)
    setLon(lon)
  }

  const markerText = building?.name

  useEffect(() => {
    if (!isSelected) setPopUpIsOpen(false)
    if (isSelected && !popUpIsOpen) {
      setTimeout(() => {
        setPopUpIsOpen(true)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected])

  useEffect(() => {
    setPosition([building?.coordinates?.[1], building?.coordinates?.[0]])
  }, [building?.coordinates])

  return (
    <Marker
      key={`${Math.random()}-${popUpIsOpen}`}
      markerText={markerText}
      position={position}
      selected={isSelected}
      className={isSelected ? 'selected-marker' : ''}
      eventHandlers={{
        click: (e) => handleMarkerOnClick(e),
        mouseover: (e) => handleMarkerOnMouseover(e),
        mouseout: (e) => handleMarkerOnMouseout(e),
        dragend: (e) => handleMarkerOnDragEnd(e),
      }}
    >
      {popUpIsOpen && (
        <MarkerPopup
          building={building}
          isActive={popUpIsOpen}
          setPopUpIsOpen={setPopUpIsOpen}
          isSelected={isSelected}
        />
      )}
      {hoverIsOpen && !popUpIsOpen && <MarkerHover building={building} />}
    </Marker>
  )
}

export default BuildingMarker

import { useDispatch, useSelector } from 'react-redux'

import { FooterContainer, Button, StyledPagination } from './Footer.style'
import Loader from './Loader'
import { ReactComponent as ARROW_LEFT } from '../../../../assets/images/arrow-left.svg'
import { ReactComponent as ARROW_RIGHT } from '../../../../assets/images/arrow-next.svg'
import { searchActionReducer } from '../../../../store/search/searchSlice'

const Footer = ({ isLoading, refetchListings }) => {
  const dispatch = useDispatch()

  const { count, nextUrl, page, previousUrl } = useSelector((state) => state.search)

  const setPage = (payload) => dispatch(searchActionReducer.setPage(payload))

  const totalPages = count ? Math.ceil(count / 10) : 0

  const handlePaginationChange = (event, { activePage }) => {
    event.preventDefault()
    event.stopPropagation()
    setPage(activePage)
    refetchListings({ page: activePage }, 'ListingsListFooter.handlePaginationChange')
  }

  const handlePreviousOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!previousUrl) return
    setPage(page - 1)
    refetchListings({ page: page - 1 }, 'ListingsListFooter.handlePreviousOnClick')
  }

  const handleNextOnClick = (event) => {
    if (!nextUrl) return
    event.preventDefault()
    event.stopPropagation()
    setPage(page + 1)
    refetchListings({ page: page + 1 }, 'ListingsListFooter.handleNextOnClick')
  }

  if (isLoading) return <Loader />

  return (
    <FooterContainer>
      <Button onClick={handlePreviousOnClick} disabled={!previousUrl}>
        <ARROW_LEFT />
      </Button>
      <StyledPagination
        activePage={page}
        totalPages={totalPages}
        firstItem={null}
        lastItem={null}
        nextItem={null}
        prevItem={null}
        siblingRange={0}
        boundaryRange={2}
        onPageChange={handlePaginationChange}
      />
      <Button onClick={handleNextOnClick} disabled={!nextUrl}>
        <ARROW_RIGHT />
      </Button>
    </FooterContainer>
  )
}

export default Footer

import { useSelector } from 'react-redux'

import { HeaderContainer, HeaderTitle } from './Header.style'
import Loader from './Loader'
import Ordering from './Ordering'

const Header = ({ listings, isLoading, refetchListings }) => {
  const { count, page } = useSelector((state) => state.search)

  const listingsRangeLowerEnd = !count ? 0 : page * 10 - 9
  const listingsRangeUpperEnd = !count ? 0 : count < page * 10 ? count : page * 10

  if (isLoading) return <Loader />

  return (
    <HeaderContainer>
      <HeaderTitle>
        Showing {listingsRangeLowerEnd}-{listingsRangeUpperEnd} of {count} listings
      </HeaderTitle>
      <Ordering listings={listings} refetchListings={refetchListings} />
    </HeaderContainer>
  )
}

export default Header

import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ActionCards from './ActionCards'
import FilterBar from './FilterBar'
import ListingTypeFilter from './FilterBar/ListingTypeFilter'
import HeroImages from './HeroImages'
import {
  HeroContainer,
  RealtorBannerButton,
  RealtorBannerButtonsContainer,
  RealtorBannerContainer,
  RealtorBannerTitle,
  RealtorBannerSubtitle,
  Title,
  TitleContainer,
  AdsContainer,
} from './Home.style'
import { USER_TYPE } from '../../constants/app.const'
import neighborhoodOptions from '../../constants/neighborhoods'
import Ads from '../Ads'
import Billboard from '../Ads/Billboard'

const Home = ({ refetchListings }) => {
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const userIsLoggedIn = !!user?.user
  const userIsRealtor = user?.user?.type === USER_TYPE.REALTOR

  const handleGetStartedOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: `home-page-realtor-get-started-clicked`,
    })
    if (!userIsLoggedIn) return navigate('/realtors/register')
    else navigate(userIsRealtor ? 'realtors' : 'account')
  }

  const metaDescription = `Explore Miami's best real estate options with OceanPads. Rent or buy apartments and condos in the vibrant Miami area. Start your search today. ${neighborhoodOptions
    ?.map((neighborhood) => neighborhood?.label)
    ?.join(', ')}`

  return (
    <>
      <Helmet>
        <title>Miami's Best Place to Find an Apartment or Condo to Rent or Buy.</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://oceanpads.com" />
      </Helmet>
      <HeroContainer>
        <HeroImages />
        <TitleContainer>
          <Title>
            The Best Place to Find an <span>Apartment</span> or <span>Condo</span> to <span>Buy</span> or{' '}
            <span>Rent</span> in Miami.
          </Title>
          <ListingTypeFilter refetchListings={refetchListings} />
          <FilterBar refetchListings={refetchListings} />
        </TitleContainer>
      </HeroContainer>
      <Billboard />
      {/* <AdsContainer className="hidden-mobile">
        <Ads variant="2" type="horizontal" />
      </AdsContainer> */}
      {/* <AdsContainer className="hidden-desktop">
        <Ads variant="1" />
      </AdsContainer> */}
      <ActionCards />
      <AdsContainer className="hidden-desktop">
        <Ads variant="2" />
        {/* <Ads variant="3" /> */}
      </AdsContainer>
      <RealtorBannerContainer>
        <RealtorBannerTitle>
          Are you a <span>real estate professional?</span>
        </RealtorBannerTitle>
        <RealtorBannerSubtitle>
          Sign in to access OceanPads, or learn how we can help grow your business.
        </RealtorBannerSubtitle>
        <RealtorBannerButtonsContainer>
          <RealtorBannerButton onClick={handleGetStartedOnClick}>Get Started</RealtorBannerButton>
          <RealtorBannerButton>Learn more</RealtorBannerButton>
        </RealtorBannerButtonsContainer>
      </RealtorBannerContainer>
    </>
  )
}

export default Home

import { Skeleton } from '@mui/material'
import styled from 'styled-components'

export const LoaderContainer = styled.div.attrs({
  className: 'LoaderContainer',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media (max-width: 992px) {
    gap: 1em;
    margin: 20px 0;
  }
`

export const LoadingSkeleton = styled(Skeleton)`
  width: 100% !important;
  display: block !important;
  position: relative !important;
  transform: none !important;
  width: 100% !important;
  height: 100% !important;
`

export const SectionContainer = styled.div.attrs({
  className: 'SectionContainer',
})`
  height: 300px;
  @media (max-width: 992px) {
    margin: 0 20px;
  }
`

import { BottomSectionContainer } from './BottomSection.style'
import SimilarListings from './SimilarListings'

const BottomSection = ({ listing }) => {
  return (
    <BottomSectionContainer>
      <SimilarListings listing={listing} />
    </BottomSectionContainer>
  )
}

export default BottomSection

import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const AmenitiesCheckboxesContainer = styled.div.attrs({
  className: 'AmenitiesCheckboxesContainer',
})`
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
`

export const AmenitiesFilterContainer = styled.div.attrs({
  className: 'AmenitiesFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    min-width: 182px;
    cursor: pointer;
    background-color: ${THEME.backgroundGray};
    position: relative;
    &:hover {
      background-color: #fafafa;
    }    
    &:after {
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
    @media (max-width: 1240px) {
      min-width: 178px;
    }
  `,
)

export const AmenitiesTriggerContainer = styled.div.attrs({
  className: 'AmenitiesTriggerContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0.5em;
`

export const AmenityOption = styled.div.attrs({
  className: 'AmenityOption',
})(
  ({ isSelected }) => `
    display: flex;
    gap: 0.5em;
    padding: 3px;
    align-items: center;
    background: ${isSelected ? 'lightblue' : THEME.backgroundGray};
    &:hover {
      background: ${isSelected ? THEME.lightBlue : 'white'};
    }
  `,
)

export const AmenityOptionCheckbox = styled.input.attrs({
  className: 'AmenityOptionCheckbox',
  type: 'checkbox',
})`
  &:checked {
    accent-color: ${THEME.mediumBlue};
  }
`

export const AmenityOptionLabel = styled.div.attrs({
  className: 'AmenityOptionLabel',
})`
  font-size: 15px;
`

import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const SearchButtonContainer = styled.div.attrs({
  className: 'SearchButtonContainer',
})`
  // width: 100%;
  // height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 15px;

  @media (max-width: 992px) {
    height: 100%;
    position: static;
    grid-column: span 2;
  }
`

export const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})`
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`

export const StyledSearchButton = styled.div.attrs({
  className: 'StyledSearchButton',
})`
  width: 44px;
  min-width: 44px;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  height: 44px;
  cursor: pointer;
  border-radius: 100%;
  background: #00669a;

  > span {
    display: none;
  }
  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    > span {
      margin-left: 0.5em;
      display: block;
    }
  }
`

import React, { useRef, useState, useEffect } from 'react'

import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import useAxios from 'axios-hooks'
import ReactGA from 'react-ga4'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import ListingCard from './ListingCard'
import {
  BuildingImage,
  IconContainer,
  HeaderContainer,
  HeaderTextContainer,
  ListingCardsContainer,
  LoadingSkeleton,
  PopupControlsContainer,
  StyledPopup,
  SubTitleContainer,
  TitleContainer,
} from './MarkerPopup.style'
import { ReactComponent as GROUPING_CLOSE } from '../../../../../../assets/images/popup-close.svg'
import { CONSUMER_SAVED_BUILDINGS, PUBLIC_BUILDINGS, PUBLIC_LISTING_PREVIEWS } from '../../../../../../constants/api'
import { BUILDING_DEFAULT } from '../../../../../../constants/image.const'
import { useBuildingContext } from '../../../../../../context/BuildingContext'
import clickEvents from '../../../../../../services/analytics.service'
import { modalsActionReducer } from '../../../../../../store/modals/modalsSlice'
import { selectionsActionReducer } from '../../../../../../store/selections/selectionsSlice'
import { groupAndSortAddresses } from '../../../../../../utilities'

const MarkerPopup = ({ building, popUpIsOpen, setPopUpIsOpen, isSelected }) => {
  const dispatch = useDispatch()

  let popupRef = useRef()

  const { user } = useSelector((state) => state.auth)
  const { building: selectedBuildingUuid } = useSelector((state) => state.selections)

  const { buildings, setBuildings } = useBuildingContext()

  const [refReady, setRefReady] = useState(false)

  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const setSelectedBuildingUuid = (payload) => dispatch(selectionsActionReducer.setBuilding(payload))

  const listingsQueryParams = { uuid__in: building?.listings?.join(',') }

  const [{ data: buildingResponse }] = useAxios(`${PUBLIC_BUILDINGS}${building?.uuid}/`)

  const [{ data: listingsResponse }] = useAxios({
    url: PUBLIC_LISTING_PREVIEWS,
    params: listingsQueryParams,
  })

  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios({
    url: CONSUMER_SAVED_BUILDINGS,
    data: { building__uuid: selectedBuildingUuid },
    method: 'GET',
  })

  const buildingMedia =
    (!!buildingResponse?.medias?.length && buildingResponse?.medias[0]?.publicImageUrl) || BUILDING_DEFAULT
  const savedBuilding = favoritesResponse?.results?.find((e) => e.building === selectedBuildingUuid)
  const isFavorite = !!savedBuilding

  const [{ loading: loadingAddFavorite }, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      data: { building: selectedBuildingUuid },
      method: 'POST',
    },
    { manual: true },
  )

  const [{ loading: loadingRemoveFavorite }, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_BUILDINGS}${savedBuilding?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const isLoading = !listingsResponse || !buildingResponse

  const saveButtonIsDisabled = loadingFavoritesResponse || loadingRemoveFavorite || loadingAddFavorite

  const addresses = groupAndSortAddresses(buildingResponse?.addresses)

  const extraAddresses = addresses?.length > 3
  const addressCards = addresses?.slice(0, 2).map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]
    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  const handleSaveFavorite = () => {
    if (!user) return openAuthModal()
    addFavorite().then(() => {
      getFavorites()
    })
  }

  const handleRemoveFavorite = () => {
    if (!user) return openAuthModal()
    removeFavorite().then(() => {
      getFavorites()
    })
  }

  const handleFavoriteOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (isFavorite) handleRemoveFavorite()
    else handleSaveFavorite()
  }

  useEffect(() => {
    if (buildingResponse && !buildings?.[building?.url]) {
      setBuildings(building?.url, buildingResponse)

      // Preload images
      buildingResponse?.medias?.slice(5).forEach((item) => {
        const imgSrc = item?.publicImageUrl
        if (imgSrc) {
          const img = new Image()
          img.src = imgSrc // Triggers preloading
        }
      })
    }
  }, [building?.url, buildingResponse, buildings, setBuildings])

  useEffect(() => {
    if (refReady && popUpIsOpen) {
      popupRef._source.openPopup()
    }
  }, [popUpIsOpen, refReady])

  const listings = listingsResponse?.results

  const handleSetRef = (r) => {
    const popUpIsOpen = !!popupRef?._source?.isPopupOpen()
    const hasSource = !!popupRef?._source
    // const shouldResetRef = refReady && hasSource && !popUpIsOpen
    const shouldResetRef = isSelected && refReady && hasSource && !popUpIsOpen

    // if (building?.uuid === 'eaf0266e-ab68-4bdb-beb6-b0be5c2dd94c')
    //   console.table({
    //     f: 'handleSetRef',
    //     refReady,
    //     isSelected,
    //     popUpIsOpen,
    //     hasSource,
    //     shouldResetRef,
    //   })

    if (shouldResetRef) {
      setSelectedBuildingUuid(null)
      setRefReady(false)
      setPopUpIsOpen(false)
      popupRef = null
      return
    }

    popupRef = r
    if (refReady) return
    setRefReady(true)
  }

  const handleCloseOnClick = () => {
    popupRef._source.closePopup()
    setPopUpIsOpen(false)
    setSelectedBuildingUuid(null)
    setRefReady(false)
    popupRef = null
  }

  const handleBuildingLinkOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'search-page-popup-building-image-clicked',
    })
    clickEvents.POPUP_BUILDING_IMAGE()
  }

  const listingCards = listings?.map((listing) => <ListingCard key={listing?.uuid} listing={listing} />)

  return (
    <StyledPopup offset={[0, -35]} ref={handleSetRef}>
      <PopupControlsContainer>
        <IconContainer onClick={handleFavoriteOnClick}>
          {saveButtonIsDisabled ? (
            <Loader size="mini" inline="centered" active color="blue" />
          ) : isFavorite ? (
            <FavoriteIcon fontSize="small" />
          ) : (
            <FavoriteBorderIcon fontSize="small" />
          )}
        </IconContainer>
        <IconContainer onClick={handleCloseOnClick}>
          <GROUPING_CLOSE />
        </IconContainer>
      </PopupControlsContainer>
      {isLoading ? (
        <LoadingSkeleton height={200} width={600} />
      ) : (
        <>
          <HeaderContainer>
            <Link to={buildingResponse?.url} onClick={handleBuildingLinkOnClick}>
              <HeaderTextContainer>
                <TitleContainer>{building?.name}</TitleContainer>
                <SubTitleContainer>
                  {extraAddresses ? addressCards.slice(0, 2) : addressCards}
                  {extraAddresses && <span>+{addresses?.length - 2} locations</span>}
                  {/* <span>
                    {building?.listings?.length} {building?.listings?.length > 1 ? 'matches' : 'match'}
                  </span> */}
                </SubTitleContainer>
              </HeaderTextContainer>
            </Link>
            <BuildingImage src={buildingMedia} />
          </HeaderContainer>
          <ListingCardsContainer>{listingCards}</ListingCardsContainer>
        </>
      )}
    </StyledPopup>
  )
}

export default MarkerPopup

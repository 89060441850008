import useAxios from 'axios-hooks'

import Item from './Item'
import Loader from './Loader'
import { CardsContainer, SavedSearchesContainer, Title } from './SavedSearches.style'
import { CONSUMER_SAVED_SEARCHES } from '../../../constants/api'

const SavedSearches = () => {
  const [{ data: savedSearchesResponse, loading: savedSearchesResponseIsLoading }] = useAxios(CONSUMER_SAVED_SEARCHES, {
    useCache: false,
  })

  const numberOfSavedSearches = savedSearchesResponse?.count
  const savedSearches = savedSearchesResponse?.results
  const savedSearchesCards = savedSearches?.map((savedSearch) => (
    <Item key={savedSearch?.uuid} savedSearch={savedSearch} />
  ))

  return (
    <SavedSearchesContainer>
      {savedSearchesResponseIsLoading ? (
        <Loader />
      ) : (
        <>
          <Title>
            {numberOfSavedSearches === 1
              ? `${numberOfSavedSearches} Saved Search`
              : `${numberOfSavedSearches} Saved Searches`}
          </Title>
          <CardsContainer>{savedSearchesCards}</CardsContainer>
        </>
      )}
    </SavedSearchesContainer>
  )
}

export default SavedSearches

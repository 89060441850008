import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'

import {
  DetailsIconContainer,
  ManagementsContainer,
  ManagementCardsContainer,
  ManagementCardContainer,
  NewWindowIconContainer,
  TitleSecondary,
  Text,
} from './Management.style'
import { ReactComponent as EMAIL_ICON } from '../../../../assets/images/agent-email.svg'
import { ReactComponent as PHONE_ICON } from '../../../../assets/images/agent-phone.svg'
import { ReactComponent as USER_ICON } from '../../../../assets/images/agent-user.svg'
import { UNIT_ICON } from '../../../../constants/image.const'
import clickEvents from '../../../../services/analytics.service'
import { SectionTitle } from '../../BuildingDetails.style'

const Management = ({ building }) => {
  const management = building?.management
  const website =
    management?.website?.replace('https://', '')?.replace('http://', '')?.replace('.com/', '.com') || 'N/A'
  const phone = management?.phone
    ? `(${management?.phone?.slice(0, 3)}) ${management?.phone?.slice(3, 6)}-${management?.phone?.slice(6, 10)}`
    : 'N/A'

  const messageSubject = encodeURIComponent(
    `OceanPads - Inquiring about ${building?.name} at ${building?.address?.value}`,
  )
  const messageBody = encodeURIComponent(
    `Hi,\r\rI found your building on OceanPads and I am interested in learning more about it.\r\r${building?.name} at ${building?.address?.value} - https://oceanpads.com${building?.url}`,
  )

  const emailLink = `mailto:${management?.email}?subject=${messageSubject}&body=${messageBody}`
  const phoneLink = management?.phone ? `+1${(management?.phone || '').replaceAll('-', '')}` : null

  const handleManagementWebsiteOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'building-page-management-name-clicked',
    })
    clickEvents.MANAGEMENT_WEBSITE()
  }
  const handleManagementEMailOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'building-page-management-email-clicked',
    })
    clickEvents.MANAGEMENT_EMAIL()
  }
  const handleManagementPhoneOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'building-page-management-phone-clicked',
    })
    clickEvents.MANAGEMENT_PHONE()
  }

  return (
    <ManagementsContainer>
      <SectionTitle>Developed By</SectionTitle>
      <TitleSecondary>{management?.name}</TitleSecondary>
      <ManagementCardsContainer>
        <ManagementCardContainer>
          <Text>
            <Link to={management?.website} target="_blank" onClick={handleManagementWebsiteOnClick}>
              <NewWindowIconContainer
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="16"
                viewBox="0 0 25 30"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path
                  fill="#407aa5"
                  d="M 12.3125 0 C 10.425781 0.00390625 10.566406 0.507813 11.5625 1.5 L 14.78125 4.71875 L 9.25 10.25 C 8.105469 11.394531 8.105469 13.230469 9.25 14.375 L 11.6875 16.78125 C 12.832031 17.921875 14.667969 17.925781 15.8125 16.78125 L 21.34375 11.28125 L 24.5 14.4375 C 25.601563 15.539063 26 15.574219 26 13.6875 L 26 3.40625 C 26 -0.03125 26.035156 0 22.59375 0 Z M 4.875 5 C 2.183594 5 0 7.183594 0 9.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 16.125 26 C 18.816406 26 21 23.816406 21 21.125 L 21 14.75 L 18 17.75 L 18 21.125 C 18 22.160156 17.160156 23 16.125 23 L 4.875 23 C 3.839844 23 3 22.160156 3 21.125 L 3 9.875 C 3 8.839844 3.839844 8 4.875 8 L 8.3125 8 L 11.3125 5 Z"
                ></path>
              </NewWindowIconContainer>
              <div>{website}</div>
            </Link>
          </Text>
          <Text>
            <Link to={emailLink} onClick={handleManagementEMailOnClick}>
              <EMAIL_ICON />
              <div>{management?.email || 'N/A'}</div>
            </Link>
          </Text>
          <Text>
            <Link to={`tel:${phoneLink}`} onClick={handleManagementPhoneOnClick}>
              <PHONE_ICON />
              <div>{phone}</div>
            </Link>
          </Text>
        </ManagementCardContainer>
        <ManagementCardContainer>
          {!!management?.architect && (
            <Text>
              <DetailsIconContainer src={UNIT_ICON} alt="architect-icon" />
              <div>Architecture by {management?.architect}</div>
            </Text>
          )}
          {!!management?.interiorDesigner && (
            <Text>
              <USER_ICON />
              <div>Interior Design by {management?.interiorDesigner}</div>
            </Text>
          )}
        </ManagementCardContainer>
      </ManagementCardsContainer>
    </ManagementsContainer>
  )
}

export default Management

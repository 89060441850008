import { useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import _debounce from 'awesome-debounce-promise'
import useAxios from 'axios-hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import GoogleIcon from './GoogleIcon'
import {
  ActiveFormContainer,
  Button,
  Divider,
  RegisterContainer,
  FormContainer,
  FormSliderContainer,
  GoogleButton,
  MessageContainer,
  StyledInput,
  SubtitleContainer,
  TextContainer,
  TitleContainer,
} from './Register'
import { REALTOR_PROFILES } from '../../../constants/api'
import { USER_TYPE } from '../../../constants/app.const'
import {
  register,
  login,
  loginAsSocial,
  getUserByUuid,
  checkEmail,
  checkPassword,
} from '../../../services/auth.service'
import { authActionReducer } from '../../../store/auth/authSlice'

export const toTitleCase = (str) =>
  str?.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1)
  })

const extractDigits = (str) => str?.replace(/\D/g, '') // Replace all non-digit characters globally with an empty string

const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const setUser = (payload) => dispatch(authActionReducer?.setUser(payload))

  const userIsLoggedIn = !!user?.user

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [authResponseIsLoading, setAuthResponseIsLoading] = useState(false)

  const [organization, setOrganization] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState(null)
  const [realtorLicenseId, setRealtorLicenseId] = useState('')

  const [activeForm, setActiveForm] = useState(userIsLoggedIn ? 1 : 0)

  const [{ loading: submitRealtorProfileIsLoading }, submitRealtorProfile] = useAxios(
    {
      url: REALTOR_PROFILES,
      method: 'POST',
      data: {
        companyName: toTitleCase(organization),
        firstName: toTitleCase(firstName),
        lastName: toTitleCase(lastName),
        phoneNumber: extractDigits(phoneNumber),
        licenseNumber: realtorLicenseId,
      },
    },
    { manual: true },
  )

  const emailResponseMessages = {
    ok: null,
    'invalid email': 'A valid email address is required',
    'email exists': 'An account with this email already exists.',
  }

  const passwordResponseMessages = {
    'This password is too short. It must contain at least 8 characters.':
      'Passwords must be at least 8 characters long.',
    'Passwords do not match.': 'Passwords do not match.',
    'This password is too common.': 'This password is too common.',
  }

  const titles = ['Create Account', 'Submit Details']
  const subtitles = ['Submit your basic information', 'Provide your public information.']

  const validData = !!email && !!password1 && !!password2 && !emailError && !passwordError && !authResponseIsLoading
  const registerButtonIsEnabled = validData
  const submitButtonIsEnabled =
    !!organization &&
    !!firstName &&
    !!lastName &&
    !phoneNumberError &&
    !!realtorLicenseId &&
    !submitRealtorProfileIsLoading

  const validateEmail = _debounce(async (value) => {
    const response = await checkEmail(value)
    setEmailError(emailResponseMessages[response])
  }, 500)

  const validatePassword = _debounce(async (value1, value2) => {
    const response = await checkPassword({ password1: value1, password2: value2 })
    if (response === 'ok') return setPasswordError(null)
    const data = JSON.parse(response)
    const errorMessage = data?.length ? data[0] : null
    setPasswordError(passwordResponseMessages[errorMessage])
  })

  const handleEmailOnChange = (event) => {
    const value = event?.target?.value
    setEmail(value)
    validateEmail(value)
  }

  const handlePassword1OnChange = (event) => {
    const value = event?.target?.value
    setPassword1(value)
    validatePassword(value, password2)
  }

  const handlePassword2OnChange = (event) => {
    const value = event?.target?.value
    setPassword2(value)
    validatePassword(password1, value)
  }

  const handleError = () => {
    toast.error('An unexpected error occurred.')
    setAuthResponseIsLoading(false)
  }

  const handleRegiseterOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAuthResponseIsLoading(true)
    register(email, password1, USER_TYPE.REALTOR).then(
      () => {
        login(email, password1).then(
          (response) => {
            setUser(response)
            setAuthResponseIsLoading(false)
            toast.info('Registration successful!')
            setActiveForm((activeForm) => (activeForm === 0 ? 1 : 0))
          },
          (error) => handleError(error),
        )
      },
      (error) => handleError(error),
    )
  }

  const handleRegisterWithGoogleOnClick = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setAuthResponseIsLoading(true)
      try {
        const data = await loginAsSocial({
          provider: 'google-oauth2',
          code: codeResponse.code,
          redirect_uri: 'postmessage',
        })
        if (data && data?.token) {
          const rsData = await getUserByUuid(data?.uuid, {
            headers: {
              authorization: 'Token ' + data?.token,
            },
          })
          const userData = {
            token: data?.token,
            user: rsData,
          }
          localStorage.setItem('user', JSON.stringify(userData))
          setUser(userData)
          setAuthResponseIsLoading(false)
          toast.info('Registration successful!')
          setActiveForm((activeForm) => (activeForm === 0 ? 1 : 0))
        }
      } catch (error) {
        handleError(error)
      }
    },
    flow: 'auth-code',
  })

  const handleSubmitButtonOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    submitRealtorProfile()
      .then(() => {
        navigate('/realtors')
        toast.info('Profile Created!')
      })
      .catch(handleError)
  }

  const normalizeInput = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
    }
  }

  const handleOrganizationOnChange = (event) => setOrganization(event?.target?.value)
  const handleFirstNameOnChange = (event) => setFirstName(event?.target?.value)
  const handleLastNameOnChange = (event) => setLastName(event?.target?.value)
  const handleRealtorLicenseIdOnChange = (event) => setRealtorLicenseId(event?.target?.value)

  const handlePhoneNumberOnChange = (event) => {
    const newPhoneNumber = event?.target?.value
    setPhoneNumber((phoneNumber) => normalizeInput(newPhoneNumber, phoneNumber))
    if (newPhoneNumber && newPhoneNumber.length < 14) setPhoneNumberError('Please enter a valid phone number.')
    else setPhoneNumberError(null)
  }

  return (
    <RegisterContainer>
      <ActiveFormContainer>
        <TitleContainer>{titles[activeForm]}</TitleContainer>
        <SubtitleContainer>{subtitles[activeForm]}</SubtitleContainer>
        <FormSliderContainer activeForm={activeForm}>
          <FormContainer>
            <StyledInput
              value={email}
              onChange={handleEmailOnChange}
              isError={emailError}
              placeholder="Work Email"
              type="email"
              autoComplete="username"
              id="email"
            />
            <MessageContainer isError={emailError}>{emailError}</MessageContainer>
            <StyledInput
              value={password1}
              onChange={handlePassword1OnChange}
              isError={passwordError}
              placeholder="Password"
              type="password"
              autoComplete="new-password"
              id="password"
            />
            <MessageContainer isError={passwordError}>{passwordError}</MessageContainer>
            <StyledInput
              value={password2}
              onChange={handlePassword2OnChange}
              isError={passwordError}
              placeholder="Confirm Password"
              type="password"
              autoComplete="new-password"
              id="confirm-password"
            />
            <MessageContainer></MessageContainer>
            <Button onClick={handleRegiseterOnClick} disabled={!registerButtonIsEnabled}>
              Next
            </Button>
            <Divider>
              <span>or</span>
            </Divider>
            <GoogleButton disabled={authResponseIsLoading} onClick={handleRegisterWithGoogleOnClick}>
              <GoogleIcon />
              <span>Continue with Google</span>
            </GoogleButton>
            <TextContainer>
              I accept OceanPads's <a href="/terms">Terms of Use</a>
            </TextContainer>
          </FormContainer>
          <FormContainer>
            <StyledInput
              value={organization}
              onChange={handleOrganizationOnChange}
              isError={passwordError}
              placeholder="Organization"
              type="organization"
              autoComplete="organization"
              id="organization"
              style={{ textTransform: 'capitalize' }}
            />
            <MessageContainer></MessageContainer>
            <StyledInput
              value={firstName}
              onChange={handleFirstNameOnChange}
              isError={passwordError}
              placeholder="First Name"
              type="given-name"
              autoComplete="given-name"
              id="given-name"
              style={{ textTransform: 'capitalize' }}
            />
            <MessageContainer></MessageContainer>
            <StyledInput
              value={lastName}
              onChange={handleLastNameOnChange}
              isError={passwordError}
              placeholder="Last Name"
              type="name"
              autoComplete="family-name"
              id="last-name"
              style={{ textTransform: 'capitalize' }}
            />
            <MessageContainer></MessageContainer>
            <StyledInput
              value={phoneNumber}
              onChange={handlePhoneNumberOnChange}
              isError={phoneNumberError}
              placeholder="Work Phone"
              type="tel"
              autoComplete="tel"
              id="tel"
            />
            <MessageContainer>{phoneNumberError}</MessageContainer>
            <StyledInput
              value={realtorLicenseId}
              onChange={handleRealtorLicenseIdOnChange}
              isError={passwordError}
              placeholder="Realtor License ID"
              type="id"
              autoComplete="id"
              id="id"
            />
            <MessageContainer></MessageContainer>
            <Button onClick={handleSubmitButtonOnClick} disabled={!submitButtonIsEnabled}>
              Submit
            </Button>
          </FormContainer>
        </FormSliderContainer>
      </ActiveFormContainer>
    </RegisterContainer>
  )
}

export default Register

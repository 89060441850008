import React, { useEffect } from 'react'

import { API_OCEAN_PADS } from '../../../constants/env.const'

const GoogleAd = ({ type }) => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        /* ignore ad errors */
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (API_OCEAN_PADS.includes('localhost'))
    return <div style={{ display: 'block', width: '100%', height: '100%', minWidth: '250px' }} />

  if (type === 'horizontal')
    return (
      <ins
        className="adsbygoogle"
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          minWidth: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-ad-client="ca-pub-8135960110041321"
        data-ad-slot="5347577283"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    )

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        minWidth: '250px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      data-ad-format="fluid"
      data-ad-layout-key="-6x+ef+1v-2l-b"
      data-ad-client="ca-pub-8135960110041321"
      data-ad-slot="5110141314"
      // data-ad-client="ca-pub-123456789"
      // data-ad-slot="1234567890"
      // data-adtest="on"
    ></ins>
  )
}

export default GoogleAd

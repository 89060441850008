import React, { useEffect, useRef, useState } from 'react'

import { GeoJSON, Tooltip, useMap } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'

import { searchActionReducer } from '../../../../store/search/searchSlice'

const NeighborhoodPolygon = ({ neighborhood, selected, setActivePolygonRefs }) => {
  const dispatch = useDispatch()
  const polygonRef = useRef()

  const { neighborhoods: selectedNeighborhoods } = useSelector((state) => state.search)

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))

  const fillColor = selected ? '#92CEDC38' : '#92CEDC14'
  const strokeColor = selected ? '#85C7D6' : '#92CEDC80'

  const handlePolygonOnClick = () => {
    const newNeighborhoods = selectedNeighborhoods?.includes(neighborhood?.uuid)
      ? selectedNeighborhoods?.filter((neighborhoodUUID) => neighborhood?.uuid !== neighborhoodUUID)
      : [neighborhood?.uuid, ...selectedNeighborhoods]
    setNeighborhoods(newNeighborhoods)
  }

  useEffect(() => {
    setActivePolygonRefs((activePolygonRefs) =>
      selected
        ? !activePolygonRefs?.includes(polygonRef)
          ? [polygonRef, ...activePolygonRefs]
          : null
        : activePolygonRefs?.filter((activePolygonRef) => activePolygonRef !== polygonRef),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <GeoJSON
      key={Math.random()}
      ref={polygonRef}
      data={neighborhood.geojson}
      color={strokeColor}
      fillColor={fillColor}
      weight={2}
      fillOpacity={1}
      eventHandlers={{
        click: handlePolygonOnClick,
      }}
    >
      <Tooltip sticky>{neighborhood.name}</Tooltip>
    </GeoJSON>
  )
}

const NeighborhoodPolygons = () => {
  const map = useMap()
  const allNeighborhoods = useSelector((state) => state.neighborhood)
  const { neighborhoods: selectedNeighborhoods } = useSelector((state) => state?.search)
  const [activePolygonRefs, setActivePolygonRefs] = useState([])
  const [mapBounds, setMapBounds] = useState([])

  useEffect(() => {
    const newMapBounds = activePolygonRefs?.map((activePolygonRef) => activePolygonRef.current.getBounds())
    if (!map || !activePolygonRefs?.length || newMapBounds === mapBounds) return
    setMapBounds(newMapBounds)
    map.fitBounds(newMapBounds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePolygonRefs])

  const neighborhoodPolygons = allNeighborhoods?.map((neighborhood) => (
    <NeighborhoodPolygon
      key={neighborhood?.uuid}
      neighborhood={neighborhood}
      selected={selectedNeighborhoods?.includes(neighborhood?.uuid)}
      setActivePolygonRefs={setActivePolygonRefs}
    />
  ))

  return neighborhoodPolygons
}

export default NeighborhoodPolygons

import React from 'react'

import { Typography } from '@mui/material'

import { StyledTooltip, WrapInformation } from './MarkerHover.style'
import { ReactComponent as GROUPING_ICON } from '../../../../../../assets/images/group-building.svg'

const MarkerHover = ({ building }) => {
  const title = building?.name
  const message =
    building?.numberOfListings === 1
      ? `${building?.numberOfListings} matching listing`
      : `${building?.numberOfListings} matching listings`
  return (
    <StyledTooltip direction="top" opacity="1" offset={[0, -38]}>
      <GROUPING_ICON />
      <WrapInformation>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="h6">{message}</Typography>
        {/* <Typography variant="h7">Click for details</Typography> */}
      </WrapInformation>
    </StyledTooltip>
  )
}

export default MarkerHover

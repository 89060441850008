import styled from 'styled-components'

import THEME from '../../../../../constants/themes'

export const FavoriteContainer = styled.div.attrs({
  className: 'FavoriteContainer',
})`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 150;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 0.5px solid #fff;
  > svg {
    width: 100%;
  }
`

export const Icon = styled.div.attrs({
  className: 'Icon',
})`
  display: flex;
  color: #333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  > svg {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
`

export const IconImage = styled.img.attrs({
  className: 'IconImage',
})`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  display: flex;
  // width: calc(100% - 60px);
  // max-width: 240px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    width: calc(100% - 70px);
  }
`

export const Image = styled.img.attrs({
  className: 'Image',
})`
  width: 100%;
  height: 100%;
  max-height: 170px;
  min-height: 170px;
  transition: 0.3s;
  object-fit: cover;
  :hover {
    transform: scale(105%);
  }
`

export const IsFeaturedContainer = styled.div.attrs({
  className: 'IsFeaturedContainer',
})`
  position: absolute;
  top: 1em;
  right: 50px;
  z-index: 150;
  padding: 5px 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemContainer = styled.div.attrs({
  className: 'ItemContainer',
})`
  // max-width: 320px;
  // width: 320px;
  // min-width: 320px;
  width: 100%;
  max-width: 350px;
  height: 320px;
  cursor: pointer !important;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  border: 0.3px solid var(--Strokes, #c4c4c4);
  box-shadow: ${THEME.boxShadow};
  @media (max-width: 992px) {
    box-shadow: none;
    max-width: 100%;
    border: none;
    border-radius: 0 0 10px 10px;
  }
  &:hover {
    box-shadow: 2px 4px 17px 0px rgba(0, 0, 0, 0.12);
    .ListingTitleContainer {
      background: #f8feff;
    }
  }
`

export const ItemDataContainer = styled.div.attrs({
  className: 'ItemDataContainer',
})`
  display: flex;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  gap: 0.5em;
`

export const ItemLowerHalfContainer = styled.div.attrs({
  className: 'ItemLowerHalfContainer',
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  // height: calc(100% - 170px);
  height: 150px;
  padding: 10px;
  padding-top: 17px;
`

export const ItemSubtitle = styled.div.attrs({
  className: 'ItemSubtitle',
})`
  font-size: 16px;
  font-weight: 600;
  color: ${THEME.mediumBlue};
`

export const MapIcon = styled.div.attrs({
  className: 'MapIcon',
})`
  display: flex;
  align-items: center;
  color: ${THEME.mediumBlue};
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: ${THEME.backgroundGray};
  padding: 10px;
  position: absolute;
  right: 0;
  border-radius: 5px;
  width: 60px;
  > svg {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
  &:hover {
    background: #f2f2f2;
    color: ${THEME.darkBlue};
  }
  @media (min-width: 992px) {
    > svg {
      width: 100%;
    }
    > span {
      display: none;
    }
  }
  @media (max-width: 992px) {
    width: 70px;
  }
`

export const PriceElement = styled.div.attrs({
  className: 'PriceElement',
})`
  font-size: 16px;
  font-weight: 600;
`

export const TextElement = styled.div.attrs({
  className: 'TextElement',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
`

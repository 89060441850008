import L from 'leaflet'
import { Marker } from 'react-leaflet'

import './markers.css'

const BuildingMarker = ({ building }) => {
  const lat = building?.coordinates[0]
  const lon = building?.coordinates[1]

  const icon = () => {
    const markerHeight = 30
    const markerWidth = building?.name?.length * 8.5 + 20
    const markerAnchorY = 43

    const iconAnchor = [markerWidth / 2, markerAnchorY]

    const markerText = building?.name

    const markerHtml = `
      <div 
        class="building-marker-icon" 
        key="${Math.random()}"
        ${`style="height: ${markerHeight}px; width: ${markerWidth}px; border-radius: 40px; border: 2.5px solid #eef3f2;"`}
      >
        ${markerText}
        </div>
      `
    return L.divIcon({
      html: markerHtml,
      iconAnchor: iconAnchor,
    })
  }

  return <Marker icon={icon()} key={`${Math.random()}`} position={[lon, lat]} />
}

export default BuildingMarker

import { useState } from 'react'

import useAxios from 'axios-hooks'
import { Link } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import Images from './Images'
import {
  Button,
  ButtonsContainer,
  CardContainer,
  Icon,
  IconImage,
  IconsContainer,
  ItemContainer,
  ItemDataContainer,
  ItemLowerHalfContainer,
  ItemSubtitle,
  TextElement,
} from './Item.style'
import { CONSUMER_SAVED_BUILDINGS } from '../../../../constants/api'
import { FLOORS_ICON, UNIT_ICON, PYRAMID_ICON } from '../../../../constants/image.const'
import { groupAndSortAddresses } from '../../../../utilities'

const Item = ({ building }) => {
  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)
  const [isFavorited, setIsFavorited] = useState(true)

  const addresses = groupAndSortAddresses(building?.addresses)

  const addressCards = addresses?.map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]

    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  const types = {
    CONDOMINIUM: 'Condominium Building',
    APARTMENT: 'Apartment Building',
  }

  const type = types[building?.type] || 'Building'

  const [, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      method: 'POST',
      data: {
        building: building?.building,
      },
    },
    { manual: true },
  )

  const [, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_BUILDINGS}${building?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const handleFavoriteOnClick = () => {
    if (favoriteIsLoading) return
    setFavoriteIsLoading(true)
    if (isFavorited)
      removeFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(false)
      })
    else
      addFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(true)
      })
  }

  return (
    <ItemContainer>
      <CardContainer>
        <Link to={building?.url}>
          <Images building={building} />
          <ItemLowerHalfContainer>
            <ItemDataContainer>
              <TextElement>
                <ItemSubtitle>{building?.buildingName}</ItemSubtitle>
                <span>
                  {type} in {building?.neighborhoodName}
                </span>
                {addressCards}
              </TextElement>
            </ItemDataContainer>
            <IconsContainer>
              <Icon>
                <IconImage src={FLOORS_ICON} alt="floors-icon" />
                {building?.numberOfUnits ? `${building?.numberOfFloors} Floors` : 'Floors unknown'}
              </Icon>
              <Icon>
                <IconImage src={UNIT_ICON} alt="unit-icon" />
                {building?.numberOfUnits ? `${building?.numberOfUnits} Units` : 'Units unknown'}
              </Icon>
              <Icon>
                <IconImage src={PYRAMID_ICON} alt="pyramid-icon" />
                {building?.yearBuilt ? `Built in ${building?.yearBuilt}` : 'Built unknown'}
              </Icon>
            </IconsContainer>
          </ItemLowerHalfContainer>
        </Link>
      </CardContainer>
      <ButtonsContainer>
        <Button onClick={handleFavoriteOnClick} isFavorited={isFavorited}>
          {favoriteIsLoading ? (
            <Loader size="mini" inline="centered" active color="white" inverted={isFavorited} />
          ) : isFavorited ? (
            'Remove'
          ) : (
            'Restore'
          )}
        </Button>
      </ButtonsContainer>
    </ItemContainer>
  )
}

export default Item

import { useDispatch, useSelector } from 'react-redux'

import {
  AmenitiesCheckboxesContainer,
  AmenitiesTriggerContainer,
  AmenitiesFilterContainer,
  AmenityOption,
  AmenityOptionCheckbox,
  AmenityOptionLabel,
} from './AmenitiesFilter.style'
import amenityOptions from '../../../../constants/amenities'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerTitle } from '../Containers/Containers.style'

const AmenitiesFilter = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { amenities } = useSelector((state) => state?.search)

  const setAmenities = (payload) => dispatch(searchActionReducer?.setAmenities(payload))

  const handleAmenityOnClick = (option, isSelected) => {
    const newAmenties = isSelected
      ? amenities?.filter((amenity) => amenity !== option?.value)
      : [...amenities, option?.value]
    setAmenities(newAmenties)
    refetchListings({ amenities: newAmenties }, 'AmenitiesFilter.handleAmenityOnClick')
  }

  const amenityCheckboxes = amenityOptions?.map((option) => {
    const isSelected = amenities?.includes(option?.value)
    return (
      <AmenityOption
        key={option?.label}
        onClick={() => handleAmenityOnClick(option, isSelected)}
        isSelected={isSelected}
      >
        <AmenityOptionCheckbox checked={isSelected} readOnly />
        <AmenityOptionLabel>{option?.label}</AmenityOptionLabel>
      </AmenityOption>
    )
  })

  return (
    <AmenitiesFilterContainer>
      <AmenitiesTriggerContainer>
        <ContainerTitle>Amenities</ContainerTitle>
        <AmenitiesCheckboxesContainer>{amenityCheckboxes}</AmenitiesCheckboxesContainer>
      </AmenitiesTriggerContainer>
    </AmenitiesFilterContainer>
  )
}

export default AmenitiesFilter

import { MapContainer as LeafletMapContainer, TileLayer } from 'react-leaflet'

import BuildingMarker from './BuildingMarker'
import { MapContainer, MapContentContainer } from './Map.style'
import { SectionTitle } from '../../BuildingDetails.style'

const accessToken = 'Dt2bOiQCnur25k4G2YQ5vN4cvVK7fr2jaKvYukA0Tk15dJyF5LF6h15Ayp5UTGeK'

const Map = ({ building }) => {
  const mapCenter = [building?.coordinates[1], building?.coordinates[0]]
  const mapZoom = 18
  const maxBounds = [
    [25.5, -80.6], // Southwest corner (latitude, longitude)
    [26.4, -80.0], // Northeast corner (latitude, longitude)
  ]

  return (
    <MapContainer>
      <SectionTitle>Location</SectionTitle>
      <MapContentContainer>
        <LeafletMapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ width: '100%', height: '100%', zIndex: 0 }}
          maxBounds={maxBounds}
          minZoom={11}
        >
          <TileLayer
            url={'https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=' + accessToken}
            attribution='&copy <a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <BuildingMarker building={building} />
        </LeafletMapContainer>
      </MapContentContainer>
    </MapContainer>
  )
}

export default Map

import { StyledInput } from './PriceInput.style'

const PriceInput = ({ value, setValue }) => {
  // State to hold the numeric value

  // Function to format the number as a price for display
  const formatPrice = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(value)
  }

  // Handle changes in the input
  const handleChange = (e) => {
    // Remove non-numeric characters before setting the state
    const numericValue = e.target.value.replace(/[^0-9.]/g, '')
    setValue(numericValue)
  }

  return <StyledInput type="text" value={formatPrice(value)} onChange={handleChange} />
}

export default PriceInput

import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const CalculatorButton = styled.button.attrs({
  className: 'CalculatorButton',
})`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  padding: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  height: 40px;
  // min-width: 162px;
  width: fit-content;
  background-color: ${THEME.mediumBlue};
  color: ${THEME.backgroundGray};
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  cursor: pointer;
  > svg {
    font-size: 17px;
  }
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  @media (max-width: 992px) {
    min-width: auto;
    flex: 1;
    max-height: 40px;
  }
`

export const Content = styled.div.attrs({
  className: 'Content',
})`
  display: flex;
  width: 100%;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding-bottom: 1.5em;
  border-bottom: 0.5px solid rgb(196, 196, 196);
  align-items: flex-end;
  @media (max-width: 992px) {
    font-size: 14px;
    padding-bottom: 1em;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
  }
`

export const Details = styled.div.attrs({
  className: 'Details',
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const NewWindowIconContainer = styled.svg.attrs({
  className: 'NewWindowIconContainer',
})`
  user-select: none;
  cursor: pointer;
  &:hover {
    > path {
      fill: #b7b7b7;
    }
  }
`

export const TaxesAndFeesContainer = styled.div.attrs({
  className: 'TaxesAndFeesContainer',
})`
  @media (max-width: 992px) {
    padding: 0 20px;
  }
`

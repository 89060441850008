import React from 'react'

import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'

import { TypeFilterContainer, TypeFilterOption, TypeFilterSwitch, TypeFilterTriggerContainer } from './TypeFilter.style'
import clickEvents from '../../../../services/analytics.service'
import { searchActionReducer } from '../../../../store/search/searchSlice'

export const TypeFilter = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { type } = useSelector((state) => state.search)

  const setType = (payload) => dispatch(searchActionReducer.setType(payload))
  const resetMaxPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  const resetMinPrice = () => dispatch(searchActionReducer.setMinPrice(null))
  const resetMaxPriceInput = () => dispatch(searchActionReducer.setMaxPriceInput(''))
  const resetMinPriceInput = () => dispatch(searchActionReducer.setMinPriceInput(''))
  const resetPriceContainer = () => dispatch(searchActionReducer.setPriceContainer('Any'))

  const switchPosition = type === 'FOR_SALE' ? 'left' : 'right'

  const handleTypeFilterOnClick = () => {
    const value = type === 'FOR_SALE' ? 'FOR_RENT' : 'FOR_SALE'
    setType(value)
    resetMaxPrice()
    resetMinPrice()
    resetMaxPriceInput()
    resetMinPriceInput()
    resetPriceContainer()
    clickEvents.TYPE_FILTER()
    refetchListings(
      {
        page: 1,
        price__gte: null,
        price__lte: null,
        type: value,
      },
      'TypeFilter.handleTypeChange',
    )
    ReactGA.event({
      category: 'action',
      action: 'search-page-type-filter-clicked',
    })
  }

  return (
    <TypeFilterContainer>
      <TypeFilterTriggerContainer onClick={handleTypeFilterOnClick}>
        <TypeFilterSwitch position={switchPosition} />
        <TypeFilterOption>Buy</TypeFilterOption>
        <TypeFilterOption>Rent</TypeFilterOption>
      </TypeFilterTriggerContainer>
    </TypeFilterContainer>
  )
}

export default TypeFilter

import AddressAndDetails from './AddressAndDetails'
import Images from './Images'
import SaveAndShare from './SaveAndShare'
import { TopSectionContainer } from './TopSection.style'
import TopSectionLoader from './TopSectionLoader'

export const TopSection = ({ listing, isLoading }) => {
  if (isLoading) return <TopSectionLoader />

  return (
    <TopSectionContainer>
      <Images listing={listing} />
      <AddressAndDetails listing={listing} />
      <SaveAndShare listing={listing} />
    </TopSectionContainer>
  )
}

export default TopSection

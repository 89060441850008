import useAxios from 'axios-hooks'
import Slider from 'react-slick'

import Item from './Item'
import { CardContainer, SimilarListingsContainer, SimilarListingsTitleContainer } from './SimilarListings.style'
import { PUBLIC_FEATURED_LISTING_PREVIEWS, PUBLIC_LISTING_PREVIEWS } from '../../../../constants/api'
import { SectionTitle } from '../../ListingDetail.style'

const SimilarListings = ({ listing }) => {
  const [{ data: featuredListingsResponse }] = useAxios(
    {
      url: PUBLIC_FEATURED_LISTING_PREVIEWS,
      params: {
        size: 3,
        listing__status: 'OPEN',
        listing__type: listing?.type,
        listing__unit__number_of_bedrooms: listing?.unit?.numberOfBedrooms,
        listing__building__neighborhood__uuid: listing?.neighborhood?.uuid,
      },
    },
    {
      useCache: false,
    },
  )

  const [{ data: listingsResponse }] = useAxios({
    url: PUBLIC_LISTING_PREVIEWS,
    params: {
      size: 13,
      status: 'OPEN',
      type: listing?.type,
      unit__number_of_bedrooms: listing?.unit?.numberOfBedrooms,
      building__neighborhood__uuid: listing?.neighborhood?.uuid,
    },
  })

  const listings = listingsResponse?.results
  const featuredListings = featuredListingsResponse?.results

  const flatFeaturedListings = featuredListings?.map((fl) => ({ ...fl?.listing, isFeatured: true })) || []
  const flatListings = listings || []
  const allListings = [...flatFeaturedListings, ...flatListings]?.filter(
    (similarListing) => similarListing?.uuid !== listing?.uuid,
  )

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const listingCards = allListings?.map((listing) => <Item key={listing?.uuid} listing={listing} />)

  return (
    <SimilarListingsContainer>
      <SimilarListingsTitleContainer>
        <SectionTitle>
          Other {listing?.unit?.numberOfBedrooms ? `${listing?.unit?.numberOfBedrooms} Bedrooms` : 'Studios'} in{' '}
          {listing?.neighborhood?.name}
        </SectionTitle>
      </SimilarListingsTitleContainer>
      <CardContainer>
        {listingCards?.length > 1 ? (
          <Slider {...settings}>{listingCards}</Slider>
        ) : listingCards?.length ? (
          <>{listingCards}</>
        ) : (
          <></>
        )}
      </CardContainer>
    </SimilarListingsContainer>
  )
}

export default SimilarListings

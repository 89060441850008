import Amenities from './Amenities'
import Description from './Description'
import Links from './Links'
import Management from './Management'
import Map from './Map'
import { MiddleSectionContainer } from './MiddleSection.style'
import MiddleSectionLoader from './MiddleSectionLoader'

const MiddleSection = ({ building, isLoading }) => {
  const hasManagement = !!building?.management && building?.isANewDevelopment
  const hasLinks = building?.links?.length > 0 && !hasManagement
  const hasAmenities = building?.amenities?.length > 0

  if (isLoading) return <MiddleSectionLoader />

  return (
    <MiddleSectionContainer>
      {hasManagement && <Management building={building} />}
      <Description building={building} />
      {hasLinks && <Links building={building} />}
      {hasAmenities && <Amenities building={building} />}
      <Map building={building} />
    </MiddleSectionContainer>
  )
}
export default MiddleSection

import Select from 'react-select'
import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const OrderingDropdownContainer = styled.div.attrs({
  className: 'OrderingDropdownContainer',
})`
  display: flex;
  align-items: center;
  gap: 10;
`

export const OrderingDropdownTitle = styled.div.attrs({ className: 'OrderingDropdownTitle' })`
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
`

export const OrderingPanelContainer = styled.div.attrs({
  className: 'OrderingPanelContainer',
})(
  ({ isOpen }) => `
    position: fixed;
    top: 0;
    background: #fff;
    height: 100dvh;
    flex-direction: column;
    width: 100vw;
    z-index: 300;
    align-items: start;
    justify-content: start;
    transition: 0.5s left;
    left: ${isOpen ? 0 : '-100vw'};
    li {
      text-align: center;
      border-radius: 4px;
      margin: 10px 25px;
      background: rgb(246, 246, 246);
      color: #333;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.2px;
      align-items: center;
      justify-content: center;
      &.active {
        border: 1px solid #226597;
        background: #fff;
        color: #226597;
      }
    }
  `,
)

export const OrderingPanelHeaderContainer = styled.div.attrs({
  className: 'OrderingPanelHeaderContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 27px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${THEME.backgroundGray};
  z-index: 300;
`

export const OrderingPanelTitle = styled.div.attrs({
  className: 'OrderingPanelTitle',
})`
  margin: 0;
  color: #000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const StyledSelect = styled(Select).attrs({
  className: 'StyledSelect',
})`
  margin-left: 5px;
  border-color: hsl(0, 0%, 80%) !important;
  > div {
    cursor: pointer;
    min-width: 170px;
    font-family: 'Nunito Sans', sans-serif;
    box-shadow: none !important;
    &:hover {
      background-color: #fafafa;
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: none !important;
      cursor: pointer !important;
    }
    &:focus {
      outline: hsl(0, 0%, 80%);
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: none !important;
    }
  }
  #react-select-9-listbox {
    cursor: pointer !important;
    z-index: 400;
  }
`

import { useEffect, useRef, useState } from 'react'

import { Skeleton } from '@mui/material'
import useAxios from 'axios-hooks'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import {
  Address,
  BuildingName,
  DropdownContainer,
  InputContainer,
  NeighborhoodName,
  OptionContainer,
  SearchBarContainer,
  SectionContainer,
  SectionHeader,
  SectionTitle,
  StyledInput,
} from './SearchBar.style'
import { PUBLIC_ADDRESSES, PUBLIC_BUILDING_NAMES } from '../../../../constants/api'
import { SEARCH_ICON } from '../../../../constants/image.const'
import neighborhoodOptions from '../../../../constants/neighborhoods'
import useOutsideClick from '../../../../hooks/outsideClick'
import clickEvents from '../../../../services/analytics.service'
import { panelsActionReducer } from '../../../../store/panels/panelsSlice'
import { searchActionReducer } from '../../../../store/search/searchSlice'

const SearchBar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const inputRef = useRef()
  const searchBarRef = useRef()

  const { headerPanelIsOpen } = useSelector((state) => state?.panels)

  const closeHeaderPanel = () => dispatch(panelsActionReducer?.setHeaderPanelIsOpen(false))
  const setNeighborhoods = (payload) => dispatch(searchActionReducer?.setNeighborhoods(payload))

  const [searchValue, setSearchValue] = useState('')
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const [{ data: addressesResponse, loading: loadingAddressesResponse }, getAddresses] = useAxios(
    {
      url: PUBLIC_ADDRESSES,
      params: {
        size: 5,
        address: searchValue,
      },
    },
    {
      manual: true,
    },
  )
  const [{ data: buildingsResponse, loading: loadingBuildingResponse }, getBuildings] = useAxios(
    {
      url: PUBLIC_BUILDING_NAMES,
      params: {
        size: 5,
        name: searchValue,
        // name__icontains: searchValue,
        // ordering: '-number_of_units',
      },
    },
    {
      manual: true,
    },
  )

  const neighborhoods = neighborhoodOptions?.filter((option) =>
    option?.label?.toLowerCase()?.includes(searchValue?.toLowerCase()),
  )
  const addresses = addressesResponse?.results
  const addressCount = addressesResponse?.count
  const buildings = buildingsResponse?.results
  const buildingsCount = buildingsResponse?.count

  const handleInputContainerOnClick = () => inputRef?.current?.focus()

  const handleInputOnChange = (event) => setSearchValue(event?.target?.value)
  const handleInputOnFocus = () => {
    if (searchValue) setDropdownIsOpen(true)
  }

  const handleAddressOnClick = () => {
    setSearchValue('')
    if (headerPanelIsOpen) closeHeaderPanel()
    ReactGA.event({
      category: 'action',
      action: 'mobile-panel-search-bar-address-clicked',
    })
    clickEvents.SEARCH_BAR_ADDRESS()
  }

  const handleBuildingOnClick = () => {
    setSearchValue('')
    if (headerPanelIsOpen) closeHeaderPanel()
    ReactGA.event({
      category: 'action',
      action: 'mobile-panel-search-bar-building-clicked',
    })
    clickEvents.SEARCH_BAR_BUILDING()
  }

  const handleNeighborhoodOnClick = (event, option) => {
    event.preventDefault()
    event.stopPropagation()
    setSearchValue('')
    setNeighborhoods([option?.value])
    navigate('/search')
    if (headerPanelIsOpen) closeHeaderPanel()
    ReactGA.event({
      category: 'action',
      action: 'mobile-panel-search-bar-neighborhood-clicked',
    })
    clickEvents.SEARCH_BAR_NEIGHBORHOOD()
  }

  const neighborhoodResults = neighborhoods?.slice(0, 5)?.map((option) => (
    <Link key={option?.value} to="/search" onClick={(event) => handleNeighborhoodOnClick(event, option)}>
      <OptionContainer key={option.value}>
        <BuildingName> {option?.label} </BuildingName>
      </OptionContainer>
    </Link>
  ))

  const uniqueBuildings = Array.from(new Set(buildings?.map((obj) => obj.url)))
    .map((url) => {
      return buildings?.find((obj) => obj.url === url)
    })
    ?.sort((a, b) => {
      const nameA = a.name.toUpperCase() // Ignore case for sorting
      const nameB = b.name.toUpperCase() // Ignore case for sorting
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
    ?.slice(0, 5)

  const buildingResults = !uniqueBuildings ? (
    'Loading...'
  ) : uniqueBuildings?.length === 0 ? (
    <div>No results.</div>
  ) : (
    uniqueBuildings?.map((building) => (
      <Link key={building?.uuid} to={building?.url} onClick={handleBuildingOnClick}>
        <OptionContainer key={building.uuid}>
          <BuildingName> {building?.name} </BuildingName>
          <NeighborhoodName> in {building?.neighborhood?.name} </NeighborhoodName>
        </OptionContainer>
      </Link>
    ))
  )

  const addressesResults = addressesResponse?.results?.map((address, index) => {
    return (
      <Link key={index} to={address?.buildingUrl} onClick={handleAddressOnClick}>
        <OptionContainer>
          <Address>{address?.oneLineAddress}</Address>
          <NeighborhoodName> {address?.buildingName} </NeighborhoodName>
        </OptionContainer>
      </Link>
    )
  })

  const showNeighborhoods = !!neighborhoodResults?.length
  const showAddresses = loadingAddressesResponse || !!addresses?.length
  const showBuildings = loadingBuildingResponse || !!buildingResults?.length
  const showNoOptions = !showNeighborhoods && !showAddresses && !showBuildings

  const handleErrors = (error) => {
    if (error?.code === 'ERR_CANCELED') return
    console.log(error)
  }

  useEffect(() => {
    if (!searchValue) {
      setDropdownIsOpen(false)
      return
    }
    getBuildings().catch(handleErrors)
    getAddresses().catch(handleErrors)
    setDropdownIsOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useOutsideClick(
    searchBarRef,
    () => {
      setDropdownIsOpen(false)
    },
    [dropdownIsOpen],
  )

  const loader = <Skeleton width="95%" height="40px" style={{ margin: 'auto' }} />

  return (
    <SearchBarContainer ref={searchBarRef} isOpen={dropdownIsOpen}>
      <InputContainer onClick={handleInputContainerOnClick}>
        <img src={SEARCH_ICON} alt="search-icon" />
        <StyledInput
          ref={inputRef}
          value={searchValue}
          onChange={handleInputOnChange}
          onFocus={handleInputOnFocus}
          type="text"
          placeholder="Search buildings, neighborhoods, or addresses..."
        />
      </InputContainer>
      <DropdownContainer isOpen={dropdownIsOpen}>
        {showNeighborhoods && (
          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Neighborhoods</SectionTitle>
              {/* Showing {neighborhoodResults?.length} of {neighborhoodsCount} */}
            </SectionHeader>
            {neighborhoodResults}
          </SectionContainer>
        )}
        {showBuildings && (
          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Buildings</SectionTitle>
              {!loadingBuildingResponse && `Showing ${buildingResults?.length} of ${buildingsCount}`}
            </SectionHeader>
            {loadingBuildingResponse ? loader : buildingResults}
          </SectionContainer>
        )}
        {showAddresses && (
          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Addresses</SectionTitle>
              {!loadingAddressesResponse && `Showing ${addressesResults?.length} of ${addressCount}`}
            </SectionHeader>
            {loadingAddressesResponse ? loader : addressesResults}
          </SectionContainer>
        )}
        {showNoOptions && <span style={{ color: '#333', padding: '5px' }}>No Results </span>}
      </DropdownContainer>
    </SearchBarContainer>
  )
}

export default SearchBar

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const CloseButtonContainer = styled.div.attrs({
  className: 'CloseButtonContainer',
})`
  font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 24px;
  top: 17.5px;
  padding: 0;
  color: #226597;
  font-size: 14px;
  font-weight: 500;
`

export const LogoContainer = styled.img.attrs({
  className: 'LogoContainer',
})`
  width: 143px;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  position: absolute;
  top: -2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
`

export const NavbarItem = styled.div.attrs({
  className: 'NavbarItem',
})`
  padding: 15px;
  height: 50px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  position: relative;
  width: 100%;
  text-align: start;
`

export const HeaderPanelContainer = styled.div.attrs({
  className: 'HeaderPanelContainer',
})(
  ({ isOpen }) => `
    position: fixed;
    top: 0;
    left: ${isOpen ? 0 : '-100vw'};
    height: 100vh;
    width: 100vw;
    transition: 0.5s all;
    z-index: 300;
  `,
)

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1em;
`

export const HeaderPanelPanelContainer = styled.div.attrs({
  className: 'HeaderPanelPanelContainer',
})`
  width: 100%;
  border-radius: 0px 10px 10px 0px;
  background: #fff;
  height: 100%;
  box-shadow: 6px -1px 17px 0px rgba(0, 0, 0, 0.08);
  position: relative;
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  padding: 18px 33px;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
  height: 66px;
  background: ${THEME.backgroundGray};
`

export const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})`
  display: flex;
  align-items: center;
  color: inherit;
  width: 100%;
`

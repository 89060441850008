import AboutTheBuilding from './AboutTheBuilding'
import Agents from './Agents'
import Amenities from './Amenities'
import Description from './Description'
import { MiddleSectionContainer } from './MiddleSection.style'
import MiddleSectionLoader from './MiddleSectionLoader'
import PriceHistory from './PriceHistory'
import TaxesAndFees from './TaxesAndFees'

const MiddleSection = ({ listing, isLoading, refetchListings }) => {
  if (isLoading) return <MiddleSectionLoader />

  return (
    <MiddleSectionContainer>
      <Agents listing={listing} />
      <Description listing={listing} />
      <Amenities listing={listing} />
      {listing?.type === 'FOR_SALE' && <TaxesAndFees listing={listing} />}
      <PriceHistory listing={listing} />
      <AboutTheBuilding listing={listing} refetchListings={refetchListings} />
    </MiddleSectionContainer>
  )
}
export default MiddleSection

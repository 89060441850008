import { useEffect, useMemo, useState } from 'react'

import { ErrorOutline } from '@mui/icons-material'
import useAxios from 'axios-hooks'

import {
  CalculatorContainer,
  CalculatorContent,
  CalculatorTitle,
  Disclaimer,
  InputContainer,
  Label,
  OptionsContainer,
  ResultsContainer,
  StyledInput,
} from './Calculator.style'
import PriceInput from './PriceInput'
import { PUBLIC_RATES } from '../../../../../constants/api'

const Calculator = ({ listing }) => {
  const [{ data: rateResponse }] = useAxios(PUBLIC_RATES)

  const priceFormatter = () =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })

  const initialPrice = useMemo(() => listing?.price, [listing])
  const initialTaxes = useMemo(() => listing?.taxAnnualAmount / 12, [listing])
  const initialHoaFee = useMemo(() => {
    const frequencyDivisor = listing?.associationFeeFrequency === 'Monthly' ? 1 : 12
    return listing?.associationFee / frequencyDivisor
  }, [listing])
  const initialDownPayment = useMemo(() => listing?.price * 0.2, [listing])
  const initialTerm = useMemo(() => 30, [])
  const initialRate = useMemo(() => Number(rateResponse), [rateResponse])

  const [price, setPrice] = useState(initialPrice)
  const [rate, setRate] = useState(initialRate)
  const [downpayment, setDownPayment] = useState(initialDownPayment)
  const [term, setTerm] = useState(initialTerm)
  const [taxes, setTaxes] = useState(initialTaxes)
  const [hoaFee, setHoaFee] = useState(initialHoaFee)

  useEffect(() => {
    if (!initialRate) return
    setRate(initialRate)
  }, [initialRate])

  const { formattedMonthlyLoanPayment, formattedMonthlyPayment, formattedTotalLoanPayment, formattedTotalPayment } =
    useMemo(() => {
      const prinicipal = price - downpayment
      const numberOfPayments = term * 12
      const monthlyRateEquivalent = rate / 100 / 12

      const monthlyLoanPayment =
        (prinicipal * (monthlyRateEquivalent * (1 + monthlyRateEquivalent) ** numberOfPayments)) /
        ((1 + monthlyRateEquivalent) ** numberOfPayments - 1)
      const monthlyPayment = monthlyLoanPayment + Number(hoaFee) + Number(taxes)
      const totalLoanPayment = monthlyLoanPayment * numberOfPayments
      const totalPayment = monthlyPayment * numberOfPayments

      const formattedMonthlyLoanPayment =
        monthlyLoanPayment && monthlyLoanPayment >= 0.0 ? priceFormatter().format(monthlyLoanPayment) : '-'
      const formattedMonthlyPayment =
        monthlyPayment && monthlyPayment >= 0.0 ? priceFormatter().format(monthlyPayment) : '-'
      const formattedTotalLoanPayment =
        totalLoanPayment && totalLoanPayment >= 0.0 ? priceFormatter().format(totalLoanPayment) : '-'
      const formattedTotalPayment = totalPayment && totalPayment >= 0.0 ? priceFormatter().format(totalPayment) : '-'

      return { formattedMonthlyLoanPayment, formattedMonthlyPayment, formattedTotalLoanPayment, formattedTotalPayment }
    }, [downpayment, hoaFee, price, rate, taxes, term])

  return (
    <CalculatorContainer>
      <CalculatorTitle>Monthly Payment Calculator</CalculatorTitle>
      <CalculatorContent>
        <OptionsContainer>
          <InputContainer>
            <Label>Asking Price</Label>
            <PriceInput placeholder="price" value={price} setValue={setPrice}></PriceInput>
          </InputContainer>
          <InputContainer>
            <Label>Mortgage Rate (%)</Label>
            {/* <PercentInput placeholder="mortgage rate" value={rate} setValue={setRate}></PercentInput> */}
            <StyledInput
              type="number"
              placeholder="mortgage rate"
              value={rate}
              onChange={(event) => setRate(event?.target?.value)}
            ></StyledInput>
          </InputContainer>
          <InputContainer>
            <Label>Down Payment ({((downpayment / price) * 100).toFixed(0)}%)</Label>
            <PriceInput placeholder="down payment" value={downpayment} setValue={setDownPayment}></PriceInput>
          </InputContainer>
          <InputContainer>
            <Label>Term (years)</Label>
            <StyledInput
              type="number"
              placeholder="term"
              value={term}
              onChange={(event) => setTerm(event?.target?.value)}
            ></StyledInput>
          </InputContainer>
          <InputContainer>
            <Label>Taxes</Label>
            <PriceInput placeholder="taxes" value={taxes} setValue={setTaxes}></PriceInput>
          </InputContainer>
          <InputContainer>
            <Label>HOA Fee</Label>
            <PriceInput placeholder="hoa fee" value={hoaFee} setValue={setHoaFee}></PriceInput>
          </InputContainer>
        </OptionsContainer>
        <ResultsContainer>
          <div>
            <div>
              <div>Monthly Estimates</div>
              <div>
                Mortgage: <b>{formattedMonthlyLoanPayment}</b>
              </div>
              <div>
                Total: <b>{formattedMonthlyPayment}</b>
              </div>
            </div>
            <div>
              <div>Term Estimate</div>
              <div>
                Loan: <b>{formattedTotalLoanPayment}</b>
              </div>
              <div>
                Total: <b>{formattedTotalPayment}</b>
              </div>
            </div>
          </div>
        </ResultsContainer>
      </CalculatorContent>
      <Disclaimer>
        <ErrorOutline />
        The values provided are estimates and are not guaranteed by any financial institution.
      </Disclaimer>
    </CalculatorContainer>
  )
}

export default Calculator

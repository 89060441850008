import { useState } from 'react'

import useAxios from 'axios-hooks'
import { Link } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import Images from './Images'
import {
  Button,
  ButtonsContainer,
  CardContainer,
  Icon,
  IconImage,
  IconsContainer,
  ItemContainer,
  ItemDataContainer,
  ItemLowerHalfContainer,
  ItemSubtitle,
  PriceElement,
  TextElement,
} from './Item.style'
import { CONSUMER_SAVED_LISTINGS } from '../../../../constants/api'
import { BED_ICON, BATH_ICON, SQURE_FOOT_ICON } from '../../../../constants/image.const'

const Item = ({ listing }) => {
  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)
  const [isFavorited, setIsFavorited] = useState(true)

  const statuses = {
    OPEN: 'Open/Available',
    PENDING: 'Pending Contract',
    CLOSED: 'Off Market',
  }

  const status = statuses[listing?.status]

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
  })

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 1,
  })

  const [, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_LISTINGS,
      method: 'POST',
      data: {
        listing: listing?.listing,
      },
    },
    { manual: true },
  )

  const [, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_LISTINGS}${listing?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const handleFavoriteOnClick = () => {
    if (favoriteIsLoading) return
    setFavoriteIsLoading(true)
    if (isFavorited)
      removeFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(false)
      })
    else
      addFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(true)
      })
  }

  return (
    <ItemContainer>
      <CardContainer>
        <Link to={listing?.url}>
          <Images listing={listing} />
          <ItemLowerHalfContainer>
            <ItemDataContainer>
              <TextElement>
                <ItemSubtitle>{listing?.building?.name}</ItemSubtitle>
                <span>{listing?.address?.value}</span>
                <span>Unit {listing?.unit?.number}</span>
              </TextElement>
              <PriceElement>
                <span>{status}</span>
                <span>{currencyFormatter.format(listing?.price)}</span>
              </PriceElement>
            </ItemDataContainer>
            <IconsContainer>
              <Icon>
                <IconImage src={BED_ICON} alt="bed-icon" />
                {listing?.unit?.numberOfBedrooms} Beds
              </Icon>
              <Icon>
                <IconImage src={BATH_ICON} alt="bath-icon" />
                {listing?.unit?.numberOfBathrooms} Baths
              </Icon>
              <Icon>
                <IconImage src={SQURE_FOOT_ICON} alt="square-foot-icon" />
                {numberFormatter.format(listing?.unit?.squareFootage)} ft²
              </Icon>
            </IconsContainer>
          </ItemLowerHalfContainer>
        </Link>
      </CardContainer>
      <ButtonsContainer>
        <Button onClick={handleFavoriteOnClick} isFavorited={isFavorited}>
          {favoriteIsLoading ? (
            <Loader size="mini" inline="centered" active color="white" inverted={isFavorited} />
          ) : isFavorited ? (
            'Remove'
          ) : (
            'Restore'
          )}
        </Button>
      </ButtonsContainer>
    </ItemContainer>
  )
}

export default Item

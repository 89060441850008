import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const ExtraChipsContainer = styled.div.attrs({
  className: 'ExtraChipsContainer',
})`
  border: 1px solid rgb(230, 230, 230);
  padding: 2px;
  border-radius: 10px;
  background: white;
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
)

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  height: 100%;
  display: flex;
  align-items: center;
`

export const NeighborhoodChipContainer = styled.div.attrs({
  className: 'NeighborhoodChipContainer',
})`
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  border-radius: 0 25px 25px 0;
  background: lightblue;
  min-width: 0px;
`

export const NeighborhoodChipIcon = styled.div.attrs({
  className: 'NeighborhoodChipIcon',
})`
  display: flex;
  height: 100%;
  width: 25px;
  align-items: center;
  border-radius: 0 25px 25px 0;
  &:hover {
    background: ${THEME.lightBlue};
  }
  > svg {
    height: 17px;
    width: 17px;
    > path {
      fill: #777 !important;
    }
  }
`

export const NeighborhoodChipValue = styled.div.attrs({
  className: 'NeighborhoodChipValue',
})`
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 3px 6px;
  border-right: 1px solid rgb(230, 230, 230);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const NeighborhoodOption = styled.div.attrs({
  className: 'NeighborhoodOption',
})(
  ({ isSelected }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Nunito Sans', sans-serif;
    width: ${isSelected ? 'fit-content' : '100%'};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    background: ${isSelected ? 'lightblue' : THEME.backgroundGray};
    padding: ${isSelected ? '3px 0 3px 5px' : '3px 10px'};
    margin-left: ${isSelected ? '5px' : '0'};
    border-radius: ${isSelected ? '0 25px 25px 0' : '0'};
    &:hover {
      background: ${isSelected ? THEME.lightBlue : 'white'};
    }
  `,
)

export const NeighborhoodsChipsContainer = styled.div.attrs({
  className: 'NeighborhoodsChipsContainer',
})(
  ({ inFocus }) => `
    width: fit-content;
    display: ${inFocus ? 'none' : 'flex'};
    align-items: center;
    height: 100%;
    margin-top: 0px;
    // max-width: calc(100% - 230px);
    // max-width: 200px;
    max-width: 100%;
    gap: 5px;
    > div {
      &:last-child {
        margin-right: 0.5em;
      }
    }
  `,
)

export const NeighborhoodsContentContainer = styled.div.attrs({
  className: 'NeighborhoodsContentContainer',
})(
  ({ maxWidth }) => `
    display: flex;
    align-items: center;
    height: 100%;
    // max-width: calc(100% - 230px);
    max-width: ${maxWidth}px;
    // background: black;
  `,
)

export const NeighborhoodsInput = styled.input.attrs({
  className: 'NeighborhoodsInput',
})(
  ({ inFocus }) => `
    width: ${inFocus ? 'auto' : '0px'};
    display: block;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    height: 30px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  `,
)

export const NeighborhoodsFilterContainer = styled.div.attrs({
  className: 'NeighborhoodsFilterContainer',
})(
  ({ isOpen }) => `
    position: relative;
    cursor: text;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: ${isOpen ? '#fafafa' : 'white'};
    &:hover {
      background-color: #fafafa;
    }
    &:after {
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
  `,
)

export const NeighborhoodsOptionsContainer = styled.div.attrs({
  className: 'NeighborhoodsOptionsContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    padding-bottom: 10px;
    width: calc(100% - 175px + 1em);
    top: 70px;
    right: -1px;
    position: absolute;
    background: #fafafa;
    border: 1px solid rgb(230, 230, 230);
    border-top: none;
    border-radius: 0 0 10px 10px;
    z-index: 185;
    max-height: 200px;
    overflow-y: auto;
    gap: 1px;
  `,
)

export const NeighborhoodsTriggerContainer = styled.div.attrs({
  className: 'NeighborhoodsTriggerContainer',
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 15px 1em 0 175px;
  cursor: text;
  white-space: nowrap;
  border-radius: 60px 0 0 60px;
`

export const OptionText = styled.div.attrs({
  className: 'OptionText',
})(
  ({ isSelected }) => `
    border-right: ${isSelected ? '1px solid rgb(230, 230, 230)' : 'none'};
    padding-right: ${isSelected ? '1em' : '0'};
  `,
)

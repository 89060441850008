import { Skeleton } from '@mui/material'

import { CardsContainer } from '../Cards.style'
import { ItemContainer } from '../Item/Item.style'

const Loader = () => {
  const cards = Array.from({ length: 10 })?.map((_, index) => (
    <ItemContainer key={index}>
      <Skeleton height="100%" width="100%" style={{ transform: 'none' }} />
    </ItemContainer>
  ))

  return <CardsContainer>{cards}</CardsContainer>
}

export default Loader

import React from 'react'
import 'semantic-ui-css/semantic.min.css'

import BedsFilter from './BedsFilter'
import { FilterContainer } from './FilterBar.style'
import NeighborhoodsFilter from './NeighborhoodsFilter'
import PriceFilter from './PriceFilter'
import SearchButton from './SearchButton'

const FilterBar = ({ refetchListings }) => (
  <FilterContainer>
    <NeighborhoodsFilter refetchListings={refetchListings} />
    <BedsFilter refetchListings={refetchListings} />
    <PriceFilter refetchListings={refetchListings} />
    <SearchButton refetchListings={refetchListings} />
  </FilterContainer>
)

export default FilterBar

import React, { useRef, useState } from 'react'

import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'

import {
  AmenitiesCheckboxesContainer,
  AmenitiesTriggerContainer,
  AmenitiesDropdownContainer,
  AmenitiesFilterContainer,
  AmenityOption,
  AmenityOptionCheckbox,
  AmenityOptionLabel,
  ButtonsContainer,
  DoneButton,
  IconsContainer,
  IconContainer,
  ResetButton,
} from './AmenitiesFilter.style'
import amenityOptions from '../../../../constants/building-amenities'
import useOutsideClick from '../../../../hooks/outsideClick'
import clickEvents from '../../../../services/analytics.service'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { CloseIcon, DownArrow } from '../Containers'
import { ContainerTitle, ContainerMessage } from '../Containers/Containers.style'

const AmenitiesFilter = () => {
  const closeIconRef = useRef()
  const containerRef = useRef()

  const dispatch = useDispatch()

  const [amenitiesFilterIsOpen, setAmenitiesFilterIsOpen] = useState(false)

  const { buildingAmenities: amenities, page, url } = useSelector((state) => state.search)
  const { buildingAmenitiesContainer: amenitiesContainer } = useSelector((state) => state.search)

  const setAmenities = (payload) => dispatch(searchActionReducer.setBuildingAmenities(payload))
  const setAmenitiesContainer = (payload) => dispatch(searchActionReducer.setBuildingAmenitiesContainer(payload))

  const numberOfOptionsSelected = amenities?.length
  const message =
    numberOfOptionsSelected === 1
      ? '1 Option Selected'
      : numberOfOptionsSelected > 1
      ? `${numberOfOptionsSelected} Options Selected`
      : 'Select Amenities'

  const closeIconIsHidden = amenitiesContainer === 'Select Amenities'

  const handleCloseIconOnClick = () => {
    setAmenities([])
    setAmenitiesContainer('Select Amenities')
    setAmenitiesFilterIsOpen(false)
  }

  const handleAmenityOnClick = (option, isSelected) => {
    setAmenities(isSelected ? amenities.filter((amenity) => amenity !== option?.value) : [...amenities, option?.value])
    console.table({ option, isSelected })
  }

  const handleAmenitiesFilterOnClose = () => {
    setAmenitiesContainer(message)
  }

  const handleAmenitiesFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setAmenitiesFilterIsOpen((amenitiesFilterIsOpen) => !amenitiesFilterIsOpen)
    if (amenitiesFilterIsOpen) handleAmenitiesFilterOnClose()
    ReactGA.event({
      category: 'action',
      action: 'new-developments-page-amenities-filter-clicked',
    })
    clickEvents.NEW_DEVELOPMENTS_AMENITIES_FILTER()
  }

  const handleDoneOnClick = () => {
    setAmenitiesFilterIsOpen(false)
    handleAmenitiesFilterOnClose()
  }

  const handleResetOnClick = () => setAmenities([])

  useOutsideClick(
    containerRef,
    () => {
      if (!amenitiesFilterIsOpen) return
      setAmenitiesFilterIsOpen(false)
      handleAmenitiesFilterOnClose()
    },
    [amenities, amenitiesFilterIsOpen, page, url],
  )

  const buildingAmenityCheckboxes = amenityOptions?.map((option) => {
    const isSelected = amenities?.includes(option?.value)

    return (
      <AmenityOption
        key={option?.label}
        onClick={() => handleAmenityOnClick(option, isSelected)}
        isSelected={isSelected}
      >
        <AmenityOptionCheckbox checked={isSelected} readOnly />
        <AmenityOptionLabel>{option?.label}</AmenityOptionLabel>
      </AmenityOption>
    )
  })

  return (
    <AmenitiesFilterContainer ref={containerRef} isOpen={amenitiesFilterIsOpen}>
      <AmenitiesTriggerContainer onClick={handleAmenitiesFilterOnClick}>
        <ContainerTitle>Amenities</ContainerTitle>
        <ContainerMessage selection={amenitiesContainer !== 'Select Amenities'}>
          {amenitiesContainer}
          <IconsContainer>
            <IconContainer isHidden={closeIconIsHidden} ref={closeIconRef}>
              <CloseIcon />
            </IconContainer>
            <IconContainer>
              <DownArrow />
            </IconContainer>
          </IconsContainer>
        </ContainerMessage>
      </AmenitiesTriggerContainer>
      <AmenitiesDropdownContainer isOpen={amenitiesFilterIsOpen}>
        <AmenitiesCheckboxesContainer>{buildingAmenityCheckboxes}</AmenitiesCheckboxesContainer>
        <ButtonsContainer>
          <ResetButton onClick={handleResetOnClick}>Reset</ResetButton>
          <DoneButton onClick={handleDoneOnClick}>Done</DoneButton>
        </ButtonsContainer>
      </AmenitiesDropdownContainer>
    </AmenitiesFilterContainer>
  )
}

export default AmenitiesFilter

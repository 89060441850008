import { memo, useEffect } from 'react'

import useAxios from 'axios-hooks'
import { useDispatch } from 'react-redux'

import { PUBLIC_NEIGHBORHOODS } from '../../constants/api'
import { neighborhoodActionReducer } from '../../store/neighborhood/neighborhoodSlice'

const DataProvider = () => {
  const dispatch = useDispatch()

  const setNeighborhoods = (payload) => dispatch(neighborhoodActionReducer?.setNeighborhoods(payload))

  const [, getNeighborhoods] = useAxios(PUBLIC_NEIGHBORHOODS, { manual: true })

  useEffect(() => {
    getNeighborhoods().then((response) => setNeighborhoods(response?.data?.results))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default memo(DataProvider)

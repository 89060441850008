import styled from 'styled-components'

import THEME from '../../constants/themes'

export const AdsContainer = styled.div.attrs({
  className: 'AdsContainer',
})(
  ({ left, width }) => `
    // width: ${width};
    // margin-left: ${left};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    > div {
      border-radius: 10px;
      // max-width: calc(100% / 3);
      height: 200px;
      min-height: 200px;
    }
    @media (max-width: 992px) {
      width: ${width};
      margin-left: ${left};
      > div {
        max-width: unset;
        border-radius: 0;
        min-height: 350px;
      }
    }
  `,
)

export const CardContainer = styled.div.attrs({
  className: 'CardContainer',
})`
  border-radius: 10px;
  border: 1px solid var(--gray-300, #dee2e7);
  background: #fff;
  padding: 25px;
  // margin-bottom: 14px;
  // box-shadow: ${THEME.boxShadow};
  @media (max-width: 992px) {
    // padding: 20px;
    padding: 0;
  }
`

export const ListingDetailsContainer = styled.div.attrs({
  className: 'ListingDetailsContainer',
})(
  ({ isNotFound }) => `
    max-width: ${isNotFound ? '' : '1440px'};
    width: 100%;
    padding: ${isNotFound ? '0' : '20px 80px'};
    margin: auto;
    display: flex;
    background: ${isNotFound ? '#74bfcc' : ''};
    position: ${isNotFound ? 'relative' : ''};;
    padding-bottom: ${isNotFound ? '200px' : ''};
    margin-bottom: ${isNotFound ? '-200px' : ''};
    flex-direction: column;
    gap: 1.5em;
    @media (max-width: 992px) {
      padding: ${isNotFound ? '' : '20px'};
      background-color: ${isNotFound ? '#74bfcc' : '#f8f9fa'};
      padding-bottom: ${isNotFound ? '100px' : ''};
      margin-bottom: ${isNotFound ? '-200px' : ''};
    }
  `,
)

export const SectionTitle = styled.h2.attrs({
  className: 'SectionTitle',
})`
  // color: #226597;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.2px;
  margin-bottom: 4px;
  @media (max-width: 992px) {
    font-size: 16px;
  }
`

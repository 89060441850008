import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  AddressAndDetailsContianer,
  AddressAndNameContainer,
  AddressContainer,
  BuildingAddress,
  BuildingName,
  DetailsContainer,
  DetailsIconContainer,
  IconsContainer,
  Item,
  StyledLink,
  UnitText,
} from './AddressAndDetails.style'
import { FLOORS_ICON, UNIT_ICON, PYRAMID_ICON } from '../../../../constants/image.const'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { groupAndSortAddresses } from '../../../../utilities'

const AddressAndDetails = ({ building, refetchListings }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))

  const type = building?.type ? building?.type?.charAt(0) + building?.type?.slice(1).toLowerCase() : 'Building'
  const units = building?.numberOfUnits
  const floors = building?.numberOfFloors
  const yearBuilt = building?.yearBuilt

  const handleNeighborhoodOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setNeighborhoods([building?.neighborhood?.uuid])
    refetchListings(
      {
        building__neighborhood__uuid__in: [building?.neighborhood?.uuid],
      },
      'AddressAndDetails.handleNeighborhoodOnClick',
    )
    navigate('/search')
  }

  const addresses = groupAndSortAddresses(building?.addresses)

  const addressCards = addresses?.map((address, index) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]

    return (
      <AddressContainer key={index}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </AddressContainer>
    )
  })

  return (
    <AddressAndDetailsContianer>
      <AddressAndNameContainer>
        <BuildingName>{building?.name}</BuildingName>
        <BuildingAddress>{addressCards}</BuildingAddress>
      </AddressAndNameContainer>
      <DetailsContainer>
        <UnitText>
          {type} in{' '}
          <StyledLink to={'/search'} onClick={handleNeighborhoodOnClick}>
            {building?.neighborhood?.name}
          </StyledLink>
        </UnitText>
        <IconsContainer>
          <Item>
            <DetailsIconContainer src={UNIT_ICON} alt="units" />
            {units} Units
          </Item>
          <Item>
            <DetailsIconContainer src={FLOORS_ICON} alt="floors" />
            {floors} Floors
          </Item>
          <Item>
            <DetailsIconContainer src={PYRAMID_ICON} alt="yearBuilt" />
            {building?.isANewDevelopment ? 'Arriving' : 'Built'} {yearBuilt}
          </Item>
        </IconsContainer>
      </DetailsContainer>
    </AddressAndDetailsContianer>
  )
}

export default AddressAndDetails

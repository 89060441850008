import { FooterContainer, Button, StyledPagination } from './Footer.style'
import Loader from './Loader'
import { ReactComponent as ARROW_LEFT } from '../../../../assets/images/arrow-left.svg'
import { ReactComponent as ARROW_RIGHT } from '../../../../assets/images/arrow-next.svg'

const Footer = ({ isLoading, page, count, setPage }) => {
  const previousIsDisabled = page === 1
  const nextIsDisabled = count < page * 10 + 1

  const totalPages = count ? Math.ceil(count / 10) : 0

  const handlePaginationChange = (event, { activePage }) => setPage(activePage)
  const handlePreviousOnClick = () => setPage(page - 1)
  const handleNextOnClick = () => setPage(page + 1)

  if (isLoading) return <Loader />

  return (
    <FooterContainer>
      <Button onClick={handlePreviousOnClick} disabled={previousIsDisabled}>
        <ARROW_LEFT />
      </Button>
      <StyledPagination
        activePage={page}
        totalPages={totalPages}
        firstItem={null}
        lastItem={null}
        nextItem={null}
        prevItem={null}
        siblingRange={0}
        boundaryRange={2}
        onPageChange={handlePaginationChange}
      />
      <Button onClick={handleNextOnClick} disabled={nextIsDisabled}>
        <ARROW_RIGHT />
      </Button>
    </FooterContainer>
  )
}

export default Footer

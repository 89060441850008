import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const HeartIconButton = styled.button.attrs({
  className: 'HeartIconButton',
})`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  background: #fff;
  padding: 10px;
  &:disabled {
    background: ${THEME.darkBlue};
    border: 1px solid ${THEME.mediumBlue};
  }
`

export const HeartIconImage = styled.img.attrs({
  className: 'HeartIconImage',
})`
  height: 100%;
  width: 100%;
`

export const SaveSearchButtonContainer = styled.div.attrs({
  className: 'SaveSearchButtonContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
`

import { useState } from 'react'

import ReactGA from 'react-ga4'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  AboutTheBuildingContainer,
  BuildingAddresses,
  BuildingDetails,
  BuildingImage,
  BuildingImageMobile,
  BuildingName,
  DetailsContainer,
  IconsContainer,
  Image,
  Item,
  NeighborhoodName,
} from './AboutTheBuilding.style'
import { BUILDING_DEFAULT, FLOORS_ICON, PYRAMID_ICON, UNIT_ICON } from '../../../../constants/image.const'
import clickEvents from '../../../../services/analytics.service'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { groupAndSortAddresses } from '../../../../utilities'
import { NewWindowIcon } from '../../../Realtors/Search/Lookup/Containers'
import { SectionTitle } from '../../ListingDetail.style'

const AboutTheBuilding = ({ listing, refetchListings }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))
  const [link, setLink] = useState(listing?.building?.url)

  const buildingName = listing?.building?.name
  const buildingLink = listing?.building?.url
  const buildingType = listing?.building?.type
    ? listing?.building?.type?.charAt(0) + listing?.building?.type?.slice(1).toLowerCase()
    : ''
  const neighborhoodName = listing?.neighborhood?.name
  const neighborhoodUuid = listing?.neighborhood?.uuid
  const salesText =
    listing?.numberOfRelatedForSaleListings === 1
      ? '1 listing for sale'
      : listing?.numberOfRelatedForSaleListings > 1
      ? `${listing?.numberOfRelatedForSaleListings} listings for sale`
      : null
  const rentalsText =
    listing?.numberOfRelatedForRentListings === 1
      ? '1 listing for rent'
      : listing?.numberOfRelatedForRentListings > 1
      ? `${listing?.numberOfRelatedForRentListings} listings for rent`
      : null
  const unitsText = listing?.building?.numberOfUnits || 'Unknown'
  const floorsText = listing?.building?.numberOfFloors || 'Unknown'
  const yearBuiltText = listing?.building?.yearBuilt || 'Unknown'
  const buildingImage = listing?.building?.medias?.[0]?.publicImageUrl || BUILDING_DEFAULT
  const buildingImageAlt = `Photo of ${buildingName} in ${neighborhoodName} facade`

  const addresses = groupAndSortAddresses(listing?.building?.addresses)

  const extraAddresses = addresses?.length > 3
  const addressCards = addresses?.slice(0, 3).map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]
    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  const handleNeighborhoodOnMouseEnter = () => setLink('/search')
  const handleNeighborhoodOnMouseLeave = () => setLink(listing?.building?.url)

  const handleNeighborhoodOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setNeighborhoods([neighborhoodUuid])
    refetchListings(
      {
        building__neighborhood__uuid__in: [neighborhoodUuid],
      },
      'AboutTheBuilding.handleNeighborhoodOnClick',
    )
    navigate('/search')
  }

  const handleAboutTheBuildingOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'listing-page-about-the-building-clicked',
    })
    clickEvents.ABOUT_THE_BUILDING()
  }

  return (
    <AboutTheBuildingContainer to={link} onClick={handleAboutTheBuildingOnClick}>
      <SectionTitle>About the Building</SectionTitle>
      <DetailsContainer>
        <BuildingImage className="hidden-mobile" src={buildingImage} alt={buildingImageAlt} />
        <BuildingImageMobile className="hidden-desktop" src={buildingImage} />
        <BuildingDetails>
          <div>
            <BuildingName>
              <span to={buildingLink}>{buildingName}</span>
            </BuildingName>
            <div onMouseEnter={handleNeighborhoodOnMouseEnter} onMouseLeave={handleNeighborhoodOnMouseLeave}>
              {buildingType || 'Building'} in{' '}
              <NeighborhoodName to="/search" onClick={handleNeighborhoodOnClick}>
                {neighborhoodName}
              </NeighborhoodName>
            </div>
            <BuildingAddresses>
              {extraAddresses ? addressCards.slice(0, 2) : addressCards}
              {extraAddresses && <span>+{addresses?.length - 2} locations</span>}
            </BuildingAddresses>
            <div> {salesText}</div>
            <div>{rentalsText}</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              Learn more <NewWindowIcon />
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <IconsContainer>
              <Item>
                <Image src={UNIT_ICON} alt="units" />
                {unitsText} Units
              </Item>
              <Item>
                <Image src={FLOORS_ICON} alt="floors" /> {floorsText} Floors
              </Item>
              <Item>
                <Image src={PYRAMID_ICON} alt="pyramid" /> Built {yearBuiltText}
              </Item>
            </IconsContainer>
          </div>
        </BuildingDetails>
      </DetailsContainer>
    </AboutTheBuildingContainer>
  )
}

export default AboutTheBuilding

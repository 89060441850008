import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'

import { OrderingPanelContainer, OrderingPanelHeaderContainer, OrderingPanelTitle } from './OrderingPanel.style'
import { ReactComponent as CLOSE_ICON } from '../../../assets/images/close.svg'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'
import { searchActionReducer } from '../../../store/search/searchSlice'

const OrderingPanel = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { orderingPanelIsOpen } = useSelector((state) => state?.panels)
  const { ordering } = useSelector((state) => state?.search)

  const setOrdering = (payload) => dispatch(searchActionReducer?.setOrdering(payload))
  const closeOrderingPanel = () => dispatch(panelsActionReducer?.setOrderingPanelsOpen(false))

  const orderingOptions = [
    { label: 'Newest', value: '-on_market_date' },
    { label: 'Recently Updated', value: '-last_modification_datetime' },
    { label: 'Most Expensive', value: '-price' },
    { label: 'Least Expensive', value: 'price' },
    { label: 'Largest', value: '-unit__square_footage' },
    { label: 'Smallest', value: 'unit__square_footage' },
  ]

  const handleOptionOnClick = (option) => {
    setOrdering(option?.value)
    closeOrderingPanel()
    refetchListings({ ordering: option?.value }, 'OrderingPanel.handleOptionOnClick')
  }

  const optionLines = orderingOptions?.map((option) => (
    <MenuItem
      key={option?.value}
      value={option?.value}
      className={option?.value === ordering ? 'active' : ''}
      onClick={() => handleOptionOnClick(option)}
    >
      {option?.label}
    </MenuItem>
  ))

  return (
    <OrderingPanelContainer className="hidden-desktop" isOpen={orderingPanelIsOpen}>
      <OrderingPanelHeaderContainer>
        <OrderingPanelTitle>Sort Results By</OrderingPanelTitle>
        <CLOSE_ICON onClick={closeOrderingPanel} />
      </OrderingPanelHeaderContainer>
      {optionLines}
    </OrderingPanelContainer>
  )
}

export default OrderingPanel

import { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import Filters from './Filters'
import List from './List'
import { SearchContainer } from './Search.style'
import SubHeader from './SubHeader'
import neighborhoodOptions from '../../constants/neighborhoods'
import findSavedSearch from '../../services/findSavedSearch.service'
import { searchActionReducer } from '../../store/search/searchSlice'
import Map from '../Map'

const Search = ({
  refetchListings,
  listingsPreviewsResponse,
  listingsGroupsResponse,
  featuredListingsResponse,
  favoritesResponse,
  savedSearchesResponse,
  loadingFeaturedListingsResponse,
  loadingListingsGroupsResponse,
  loadingListingsPreviewsResponse,
  loadingFavoritesResponse,
  savedSearchesResponseIsLoading,
  getFavorites,
  getSearches,
}) => {
  const dispatch = useDispatch()

  const activeParams = useSelector((state) => state.search)
  const { user } = useSelector((state) => state.auth)

  const setSavedSearch = (payload) => dispatch(searchActionReducer?.setSavedSearch(payload))

  const userIsLoggedIn = !!user?.user

  const [map, setMap] = useState(null)

  const isLoading =
    !listingsGroupsResponse ||
    !listingsPreviewsResponse ||
    !featuredListingsResponse ||
    loadingListingsGroupsResponse ||
    loadingFeaturedListingsResponse ||
    loadingListingsPreviewsResponse

  const buildings = listingsGroupsResponse?.results
  const listings = listingsPreviewsResponse?.results
  const featuredListings = featuredListingsResponse?.results
  const favorites = favoritesResponse?.results
  const searches = savedSearchesResponse?.results

  useEffect(() => {
    if (!userIsLoggedIn) return
    if (!searches) getSearches()
    const savedSearch = findSavedSearch(activeParams, searches)
    setSavedSearch(savedSearch)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, searches])

  const metaDescription = `Browse local listings in Miami on OceanPads. ${neighborhoodOptions
    ?.map((neighborhood) => neighborhood?.label)
    ?.join(', ')}`

  return (
    <SearchContainer>
      <Helmet>
        <title>OceanPads | Search</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://oceanpads.com/search" />
      </Helmet>
      <SubHeader refetchListings={refetchListings} savedSearchesResponseIsLoading={savedSearchesResponseIsLoading} />
      <Filters refetchListings={refetchListings} savedSearchesResponseIsLoading={savedSearchesResponseIsLoading} />
      <List
        loadingFavoritesResponse={loadingFavoritesResponse}
        favorites={favorites}
        getFavorites={getFavorites}
        featuredListings={featuredListings}
        listings={listings}
        map={map}
        isLoading={isLoading}
        refetchListings={refetchListings}
      />
      <Map buildings={buildings} isLoading={isLoading} refetchListings={refetchListings} setMap={setMap} />
    </SearchContainer>
  )
}

export default Search

import React from 'react'

import { Typography } from '@mui/material'

import { AddressContainer, StyledTooltip, WrapInformation } from './MarkerHover.style'
import { ReactComponent as GROUPING_ICON } from '../../../../../../../assets/images/group-building.svg'
import { groupAndSortAddresses } from '../../../../../../../utilities'

const MarkerHover = ({ building }) => {
  const title = building?.name

  const addresses = groupAndSortAddresses(building?.addresses)

  const extraAddresses = addresses?.length > 3
  const addressCards = addresses?.slice(0, 2).map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]
    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  return (
    <StyledTooltip direction="top" opacity="1" offset={[0, -38]}>
      <GROUPING_ICON />
      <WrapInformation>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="h6">
          <AddressContainer>
            {extraAddresses ? addressCards.slice(0, 2) : addressCards}
            {extraAddresses && <span>+{addresses?.length - 2} locations</span>}
          </AddressContainer>
        </Typography>
        {/* <Typography variant="h7">Click for details</Typography> */}
      </WrapInformation>
    </StyledTooltip>
  )
}

export default MarkerHover

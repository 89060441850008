import React from 'react'

import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'

import { ListingTypeFilterContainer, RadioButton } from './ListingTypeFilter.style'
import { searchActionReducer } from '../../../../store/search/searchSlice'

export const ListingTypeFilter = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { type } = useSelector((state) => state.search)

  const setType = (payload) => dispatch(searchActionReducer.setType(payload))
  const resetMaxPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  const resetMinPrice = () => dispatch(searchActionReducer.setMinPrice(null))
  const resetMaxPriceInput = () => dispatch(searchActionReducer.setMaxPriceInput(''))
  const resetMinPriceInput = () => dispatch(searchActionReducer.setMinPriceInput(''))
  const resetPriceContainer = () => dispatch(searchActionReducer.setPriceContainer('Any'))

  const handleOnClick = () => {
    const value = type === 'FOR_SALE' ? 'FOR_RENT' : 'FOR_SALE'
    resetMaxPrice()
    resetMinPrice()
    resetMaxPriceInput()
    resetMinPriceInput()
    resetPriceContainer()
    setType(value)
    refetchListings({ type: value, price__lte: null, price__gte: null }, 'ListingTypeFilter.handleOnClick')
    ReactGA.event({
      category: 'action',
      action: 'home-page-listing-type-filter-clicked',
    })
  }

  return (
    <ListingTypeFilterContainer>
      <RadioButton selected={type === 'FOR_SALE'} onClick={handleOnClick}>
        BUY A HOME
      </RadioButton>
      <RadioButton selected={type === 'FOR_RENT'} onClick={handleOnClick}>
        RENT A HOME
      </RadioButton>
    </ListingTypeFilterContainer>
  )
}

export default React.memo(ListingTypeFilter)

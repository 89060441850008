import styled from 'styled-components'

import THEME from '../../../../../constants/themes'

export const CalculatorContainer = styled.div.attrs({
  className: 'CalculatorContainer',
})`
  background: ${THEME.lightBlue};
  border-radius: 5px;
  // width: 50%;
  overflow: hidden;
  // margin-top: -30px;
  border: 2px solid ${THEME.whiteBlue};
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 0;
    height: 100%;
    border: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const CalculatorTitle = styled.div.attrs({
  className: 'CalculatorTitle',
})`
  font-size: 16px;
  padding: 10px;
  background: ${THEME.mediumBlue};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CalculatorContent = styled.div.attrs({
  className: 'CalculatorContent',
})`
  display: flex;
  padding: 10px;
  flex-direction: column;
`

export const Content = styled.div.attrs({
  className: 'Content',
})`
  display: flex;
  width: 100%;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding-bottom: 1.5em;
  border-bottom: 0.5px solid rgb(196, 196, 196);
  @media (max-width: 992px) {
    font-size: 14px;
    padding-bottom: 1em;
    flex-direction: column;
    gap: 1em;
  }
`

export const Details = styled.div.attrs({
  className: 'Details',
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Disclaimer = styled.div.attrs({
  className: 'Disclaimer',
})`
  font-size: 12px;
  display: flex;
  padding: 10px;
  > svg {
    font-size: 17px;
  }
  align-items: center;
  gap: 3px;
`

export const InputContainer = styled.div.attrs({
  className: 'InputContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  // flex-direction: column;
  gap: 1em;
  > div {
    width: 50%;
  }
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`

export const Label = styled.label.attrs({
  className: 'Label',
})`
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
`

export const OptionsContainer = styled.div.attrs({
  className: 'OptionsContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  &:after {
    display: flex;
    justify-content: center;
    content: '';
    grid-column: 1 / -1;
    // width: 80%;
    // background: #ccc;
    // border-bottom: 1px solid #999;
  }
`

export const ResultsContainer = styled.div.attrs({
  className: 'ResultsContainer',
})`
  display: flex;
  flex-direction: column;
  // padding: 10px;
  border-top: 0.5px solid rgb(196, 196, 196);
  > div {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    gap: 2em;
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  background: white;
  color: black;
  height: 34px;
  border: none;
  border-bottom: 0.5px solid #aaa;
  padding: 10px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  // transition: 0.2s all ease-in-out;
  border-radius: 3px;
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`

import { useDispatch, useSelector } from 'react-redux'

import AmenitiesFilter from './AmenitiesFilter'
import BedsFilter from './BedsFilter'
import {
  DoneButton,
  HeaderTitle,
  FilterPanelBodyContainer,
  FilterPanelContainer,
  FilterPanelFooterContainer,
  FilterPanelHeaderContainer,
  ResetButton,
} from './FiltersPanel.style'
import MoreFilter from './MoreFilter'
import NeighborhoodsFilter from './NeighborhoodsFilter'
import PriceFilter from './PriceFilter'
import { ReactComponent as CLOSE_ICON } from '../../../assets/images/close.svg'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'
import { searchActionReducer } from '../../../store/search/searchSlice'

export const FiltersPanel = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { filterPanelIsOpen } = useSelector((state) => state?.panels)

  const closeFilterPanel = () => dispatch(panelsActionReducer?.setFilterPanelIsOpen(false))

  const resetFilters = () => {
    dispatch(searchActionReducer.setAmenities([]))
    dispatch(searchActionReducer.setBathrooms(null))
    dispatch(searchActionReducer.setBeds([]))
    dispatch(searchActionReducer.setMaxPrice(null))
    dispatch(searchActionReducer.setMinPrice(null))
    dispatch(searchActionReducer.setNeighborhoods([]))
    dispatch(searchActionReducer.setPets([]))
    dispatch(searchActionReducer.setSquareFeet(null))
    dispatch(searchActionReducer.setYearBuilt(null))

    dispatch(searchActionReducer.setMaxPriceInput(''))
    dispatch(searchActionReducer.setMinPriceInput(''))
    dispatch(searchActionReducer.setBathroomsInput(''))
    dispatch(searchActionReducer.setSquareFeetInput(''))
    dispatch(searchActionReducer.setYearBuiltInput(''))

    dispatch(searchActionReducer.setBedsContainer('Any'))
    dispatch(searchActionReducer.setPriceContainer('Any'))
    dispatch(searchActionReducer.setAmenitiesContainer('Select Amenities'))
    dispatch(searchActionReducer.setMoreContainer('Browse Other Options'))
    refetchListings(
      {
        building__neighborhood__uuid__in: null,
        price__gte: null,
        price__lte: null,
        unit__number_of_bedrooms__gte: null,
        unit__number_of_bedrooms__lte: null,
        beds: null,
        amenities: null,
        pets: null,
        unit__number_of_bathrooms__gte: null,
        unit__square_footage__gte: null,
        building__year_built__gte: null,
      },
      'FiltersPanel.resetFilters',
    )
  }

  const handleResetFiltersOnClick = () => resetFilters()

  return (
    <FilterPanelContainer className="hidden-desktop" isOpen={filterPanelIsOpen}>
      <FilterPanelHeaderContainer>
        <HeaderTitle>Filters</HeaderTitle>
        <CLOSE_ICON onClick={closeFilterPanel} />
      </FilterPanelHeaderContainer>
      <FilterPanelBodyContainer>
        <NeighborhoodsFilter refetchListings={refetchListings} />
        <PriceFilter refetchListings={refetchListings} />
        <BedsFilter refetchListings={refetchListings} />
        <AmenitiesFilter refetchListings={refetchListings} />
        <MoreFilter refetchListings={refetchListings} />
      </FilterPanelBodyContainer>
      <FilterPanelFooterContainer>
        <ResetButton onClick={handleResetFiltersOnClick}>Reset All Filters</ResetButton>
        <DoneButton onClick={closeFilterPanel}>Apply Filters</DoneButton>
      </FilterPanelFooterContainer>
    </FilterPanelContainer>
  )
}

export default FiltersPanel

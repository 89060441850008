import { useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import {
  Button,
  Divider,
  FormContainer,
  GoogleButton,
  LoginContainer,
  MessageContainer,
  StyledInput,
  TextContainer,
} from './Login.style'
import { login, loginAsSocial, getUserByUuid } from '../../../../services/auth.service'
import { authActionReducer } from '../../../../store/auth/authSlice'
import { modalsActionReducer } from '../../../../store/modals/modalsSlice'
import GoogleIcon from '../GoogleIcon'

const Login = () => {
  const dispatch = useDispatch()

  const closeAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(false))
  const setUser = (payload) => dispatch(authActionReducer?.setUser(payload))

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const [authResponseIsLoading, setAuthResponseIsLoading] = useState(false)

  const validData = !!email && !!password && !authResponseIsLoading
  const signInButtonIsEnabled = validData

  const handleEmailOnChange = (event) => setEmail(event?.target?.value)

  const handlePasswordOnChange = (event) => setPassword(event?.target?.value)

  const handleError = (error) => {
    setAuthResponseIsLoading(false)
    const message = error?.response?.data?.non_field_errors
    if (message) return setError(message)
    toast.error('An unexpected error occurred.')
  }

  const handleSignInOnClick = () => {
    if (!signInButtonIsEnabled) return
    setAuthResponseIsLoading(true)
    login(email, password).then(
      (response) => {
        closeAuthModal()
        setUser(response)
        setAuthResponseIsLoading(false)
        toast.info('Sign in successful!')
      },
      (error) => handleError(error),
    )
  }

  const handleOnKeyDown = (event) => {
    if (event?.key === 'Enter') handleSignInOnClick()
  }

  const handleLoginWithGoogleOnClick = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setAuthResponseIsLoading(true)
      try {
        const data = await loginAsSocial({
          provider: 'google-oauth2',
          code: codeResponse.code,
          redirect_uri: 'postmessage',
        })
        if (data && data?.token) {
          const rsData = await getUserByUuid(data?.uuid, {
            headers: {
              authorization: 'Token ' + data?.token,
            },
          })
          const userData = {
            token: data?.token,
            user: rsData,
          }
          localStorage.setItem('user', JSON.stringify(userData))
          closeAuthModal()
          setUser(userData)
          setAuthResponseIsLoading(false)
          toast.info('Sign in successful!')
        }
      } catch (error) {
        handleError(error)
      }
    },
    flow: 'auth-code',
  })

  return (
    <LoginContainer>
      <TextContainer>Take full advantage of OceanPads's features</TextContainer>
      <FormContainer>
        <StyledInput
          value={email}
          isError={error}
          onChange={handleEmailOnChange}
          placeholder="Email"
          type="email"
          id="email"
          autoComplete="email"
          onKeyDown={handleOnKeyDown}
        />
        <MessageContainer></MessageContainer>
        <StyledInput
          value={password}
          isError={error}
          onChange={handlePasswordOnChange}
          placeholder="Password"
          type="password"
          id="password"
          autoComplete="current-pasword"
          onKeyDown={handleOnKeyDown}
        />
        <MessageContainer isError={error}>{error}</MessageContainer>
        <span style={{ height: 40 }} />
      </FormContainer>
      <TextContainer></TextContainer>
      <Button onClick={handleSignInOnClick} disabled={!signInButtonIsEnabled}>
        Sign In
      </Button>
      <Divider>
        <span>or</span>
      </Divider>
      <GoogleButton disabled={authResponseIsLoading} onClick={handleLoginWithGoogleOnClick}>
        <GoogleIcon />
        <span>Continue with Google</span>
      </GoogleButton>
      <TextContainer>
        I accept OceanPads's <a href="/terms">Terms of Use</a>
      </TextContainer>
    </LoginContainer>
  )
}

export default Login

import { useState } from 'react'

import { useSelector } from 'react-redux'

import {
  Button,
  CardContainer,
  FormContainer,
  Image,
  Message,
  MessageContainer,
  WrapContent,
  SettingsContainer,
  StyledInput,
} from './Settings.style'
import { BUILDING_DEFAULT, HERO_MOBILE, NOTFOUND_DEFAULT } from '../../../constants/image.const'

const Settings = () => {
  const { user } = useSelector((state) => state.auth)

  const [email, setEmail] = useState(user?.user?.email)
  const [password, setPassword] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleEmailOnChange = (event) => setEmail(event?.target?.value)
  const handlePasswordOnChange = (event) => setPassword(event?.target?.value)
  const handlePassword1OnChange = (event) => setPassword1(event?.target?.value)
  const handlePassword2OnChange = (event) => setPassword2(event?.target?.value)

  return (
    <SettingsContainer>
      <WrapContent>
        <CardContainer>
          <FormContainer>
            My Email
            <StyledInput value={email} disabled />
            <Message>We are still doing some work around here, check back later for some new features!</Message>
            {/* <Button>Edit</Button>
          My Password
          <StyledInput value={'****'} disabled />
          <Button>Edit</Button> */}
          </FormContainer>
          {/* <FormContainer>
          My Email:
          <StyledInput
            value={email}
            onChange={handleEmailOnChange}
            isError={emailError}
            placeholder="Email"
            type="email"
            autoComplete="username"
            id="email"
          />
          <MessageContainer isError={emailError}>{emailError}</MessageContainer>
          <StyledInput
            value={password1}
            onChange={handlePassword1OnChange}
            isError={passwordError}
            placeholder="Password"
            type="password"
            autoComplete="new-password"
            id="password"
          />
          <MessageContainer isError={passwordError}>{passwordError}</MessageContainer>
          <StyledInput
            value={password2}
            onChange={handlePassword2OnChange}
            isError={passwordError}
            placeholder="Confirm Password"
            type="password"
            autoComplete="new-password"
            id="confirm-password"
          />
        </FormContainer> */}
        </CardContainer>
        <Image src={NOTFOUND_DEFAULT} />
      </WrapContent>
    </SettingsContainer>
  )
}

export default Settings

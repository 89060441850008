import { useDispatch, useSelector } from 'react-redux'

import {
  MoreCheckboxesContainer,
  MoreFilterContainer,
  MoreFilterTriggerContainer,
  MoreOption,
  MoreOptionCheckbox,
  MoreOptionLabel,
  MoreOptionSubtitle,
} from './MoreFilter.style'
import MoreFilterDropdown from './MoreFilterDropdown'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerTitle } from '../Containers/Containers.style'

const MoreFilter = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { bathrooms, squareFeet, pets, yearBuilt } = useSelector((state) => state?.search)
  const { bathroomsInput, squareFeetInput, yearBuiltInput } = useSelector((state) => state.search)

  const setBathrooms = (payload) => dispatch(searchActionReducer?.setBathrooms(payload))
  const setSquareFeet = (payload) => dispatch(searchActionReducer?.setSquareFeet(payload))
  const setYearBuilt = (payload) => dispatch(searchActionReducer?.setYearBuilt(payload))
  const setPets = (payload) => dispatch(searchActionReducer?.setPets(payload))
  const setBathroomsInput = (payload) => dispatch(searchActionReducer.setBathroomsInput(payload))
  const setSquareFeetInput = (payload) => dispatch(searchActionReducer.setSquareFeetInput(payload))
  const setYearBuiltInput = (payload) => dispatch(searchActionReducer.setYearBuiltInput(payload))

  const bathroomOptions = [
    { label: '1 or More', value: 1 },
    { label: '1.5 or More', value: 1.5 },
    { label: '2 or More', value: 2 },
    { label: '2.5 or More', value: 2.5 },
    { label: '3 or More', value: 3 },
    { label: '3.5 or More', value: 3.5 },
    { label: '4 or More', value: 4 },
  ]

  const squareFeetOptions = [
    { label: '500+', value: 500 },
    { label: '600+', value: 600 },
    { label: '700+', value: 700 },
    { label: '800+', value: 800 },
    { label: '900+', value: 900 },
    { label: '1000+', value: 1000 },
    { label: '1250+', value: 1250 },
    { label: '1500+', value: 1500 },
    { label: '2000+', value: 2000 },
    { label: '2500+', value: 2500 },
    { label: '3000+', value: 3000 },
    { label: '3500+', value: 3500 },
    { label: '4000+', value: 4000 },
    { label: '5000+', value: 5000 },
  ]

  const yearBuiltOptions = [
    { label: '1980', value: 1980 },
    { label: '1990', value: 1990 },
    { label: '2000', value: 2000 },
    { label: '2005', value: 2005 },
    { label: '2010', value: 2010 },
    { label: '2012', value: 2012 },
    { label: '2014', value: 2014 },
    { label: '2016', value: 2016 },
    { label: '2018', value: 2018 },
    { label: '2020', value: 2020 },
    { label: '2022', value: 2022 },
    { label: '2023', value: 2023 },
    { label: '2024', value: 2024 },
    { label: '2025', value: 2025 },
  ]

  const petOptions = [
    { label: 'Cats OK', value: 'Cats OK' },
    { label: 'Dogs OK', value: 'Dogs OK' },
    { label: 'All Pets', value: 'Pets Allowed' },
  ]

  const handlePetsOnClick = (option, isSelected) => {
    const selectedPets = isSelected ? pets?.filter((pet) => pet !== option?.value) : [...pets, option?.value]
    setPets(selectedPets)
    refetchListings({ pets: selectedPets?.join(',') || null }, 'MoreFilter.handlePetsOnClick')
  }

  //   unit__number_of_bathrooms__gte: bathrooms,
  //   unit__square_footage__gte: squareFeet,

  const petCheckboxes = petOptions?.map((option) => {
    const isSelected = pets?.includes(option?.value)
    return (
      <MoreOption key={option?.label} onClick={() => handlePetsOnClick(option, isSelected)} isSelected={isSelected}>
        <MoreOptionCheckbox checked={isSelected} readOnly />
        <MoreOptionLabel>{option?.label}</MoreOptionLabel>
      </MoreOption>
    )
  })

  return (
    <MoreFilterContainer>
      <MoreFilterTriggerContainer>
        <ContainerTitle>More</ContainerTitle>
        <MoreFilterDropdown
          title="Square Feet"
          placeholder="Any"
          inputValue={squareFeetInput}
          setInputValue={setSquareFeetInput}
          moreValue={squareFeet}
          setMoreValue={setSquareFeet}
          options={squareFeetOptions}
          refetchListings={refetchListings}
          moreFilterQueryParam="unit__square_footage__gte"
        />
        <MoreFilterDropdown
          title="Bathrooms"
          placeholder="Any"
          inputValue={bathroomsInput}
          setInputValue={setBathroomsInput}
          moreValue={bathrooms}
          setMoreValue={setBathrooms}
          options={bathroomOptions}
          refetchListings={refetchListings}
          moreFilterQueryParam="unit__number_of_bathrooms__gte"
        />
        <MoreFilterDropdown
          title="Year Built"
          placeholder="In or After"
          inputValue={yearBuiltInput}
          setInputValue={setYearBuiltInput}
          moreValue={yearBuilt}
          setMoreValue={setYearBuilt}
          options={yearBuiltOptions}
          refetchListings={refetchListings}
          moreFilterQueryParam="building__year_built__gte"
        />
        <MoreCheckboxesContainer>
          <MoreOptionSubtitle>Pets</MoreOptionSubtitle>
          {petCheckboxes}
        </MoreCheckboxesContainer>
      </MoreFilterTriggerContainer>
    </MoreFilterContainer>
  )
}

export default MoreFilter

import { useEffect, useMemo, useState } from 'react'

import useAxios from 'axios-hooks'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import BottomSection from './BottomSection'
import { AdsContainer, CardContainer, ListingDetailsContainer } from './ListingDetail.style'
import MiddleSection from './MiddleSection'
import NotFound from './NotFound'
import transformToSchemaOrg from './schemaConvertor'
import TopSection from './TopSection'
import { PUBLIC_BUILDINGS } from '../../constants/api'
import { useBuildingContext } from '../../context/BuildingContext'
import { useListingContext } from '../../context/ListingContext'
import * as api from '../../services'
import Ads from '../Ads'

const ListingDetails = ({ refetchListings }) => {
  const { listings } = useListingContext()
  const { buildings, setBuildings } = useBuildingContext()

  const { detailId, neighborhoodName, buildingName, unitNumber, towerName, listingType } = useParams()

  const [listing, setListing] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [listingsPageMargin, setListingsPageMargin] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)

  const [, getBuildingDetails] = useAxios(`${PUBLIC_BUILDINGS}${listing?.building?.uuid}/`, {
    autoCancel: false,
    manual: true,
  })

  const isMobile = windowWidth <= 992

  const adsLeft = isMobile ? '-20px' : `-${listingsPageMargin + 80}px`
  const adsWidth = isMobile ? 'calc(100% + 40px)' : `calc(100% + ${listingsPageMargin * 2 + 160}px)`

  const setWindowDimensions = () => setWindowWidth(window.innerWidth)

  const retrieveMargin = (elm) => {
    if (elm != null) {
      let styles = window.getComputedStyle(elm)
      let ml = styles.getPropertyValue('margin-left')
      setListingsPageMargin(Number.parseInt(ml.replace('px', '')))
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  useEffect(() => {
    const preloadedData = listings?.[window.location.pathname]
    if (preloadedData) {
      setIsLoading(false)
      setListing(preloadedData)
      return
    }
    setIsLoading(true)
    const getListingFromUrl = () => {
      if (detailId) return api.getListingById(detailId)
      else return api.getListingBySearch(neighborhoodName, buildingName, unitNumber, towerName, listingType)
    }
    getListingFromUrl()
      .then((response) => {
        setListing(response?.data)
        setIsLoading(false)
      })
      .catch((error) => {
        // if (error?.response?.status === 404) navigate(`/${neighborhoodName}/${buildingName}`)
        // if (error?.response?.status === 404) navigate('/not-found')
        if (error?.response?.status === 404) setNotFound(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailId, neighborhoodName, buildingName, unitNumber, towerName, listingType])

  useEffect(() => {
    if (listing?.building?.uuid && !buildings[listing?.building?.url]) {
      getBuildingDetails(`${PUBLIC_BUILDINGS}${listing?.building?.uuid}/`)?.then((response) => {
        setBuildings(listing?.building?.url, response?.data)

        // Preload images
        response?.data?.medias?.slice(5).forEach((item) => {
          const imgSrc = item?.publicImageUrl
          if (imgSrc) {
            const img = new Image()
            img.src = imgSrc // Triggers preloading
          }
        })
      })
    }
  }, [listing?.building?.uuid, getBuildingDetails, listing?.building?.url, setBuildings, buildings])

  const type = listing?.type === 'FOR_RENT' ? 'for Rent' : 'for Sale'
  const metaTitle =
    listing && !isLoading
      ? listing?.status === 'OPEN'
        ? `${listing?.building?.name} | Unit ${listing?.unit?.number} ${type}`
        : `${listing?.building?.name} | Unit ${listing?.unit?.number} Off Market`
      : 'OceanPads'
  const metaDescription = `Browse unit ${listing?.unit?.number} ${type?.toLocaleLowerCase()} in ${
    listing?.building?.name
  } in the ${listing?.neighborhood?.name} neighborhood, located at ${listing?.address?.value} on OceanPads.`
  const canonicalUrl = listing && !isLoading ? `https://www.oceanpads.com${listing?.url}` : ''

  const schemaString = useMemo(() => {
    const schemaOrgJson = transformToSchemaOrg(listing)
    const schemaString = JSON.stringify(schemaOrgJson)
    return schemaString
  }, [listing])

  if (notFound)
    return (
      <ListingDetailsContainer ref={retrieveMargin} isNotFound>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <link rel="canonical" href={canonicalUrl} />
          <script type="application/ld+json">{schemaString}</script>
        </Helmet>
        <NotFound listing={listing} />
      </ListingDetailsContainer>
    )

  return (
    <ListingDetailsContainer ref={retrieveMargin}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <script type="application/ld+json">{schemaString}</script>
      </Helmet>
      <CardContainer>
        <TopSection listing={listing} isLoading={isLoading} />
      </CardContainer>
      <CardContainer>
        <MiddleSection listing={listing} isLoading={isLoading} refetchListings={refetchListings} />
      </CardContainer>
      <AdsContainer className="hidden-mobile" left={adsLeft} width={adsWidth}>
        <Ads variant="2" type="horizontal" />
      </AdsContainer>
      <AdsContainer className="hidden-desktop" left={adsLeft} width={adsWidth}>
        <Ads variant="2" />
      </AdsContainer>
      <CardContainer>
        <BottomSection listing={listing} />
      </CardContainer>
    </ListingDetailsContainer>
  )
}

export default ListingDetails

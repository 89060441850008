import Grid from '@mui/material/Unstable_Grid2'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import {
  ActionCardBodyContainer,
  ActionCardContainer,
  ActionCardContentContainer,
  ActionCardHeaderImage,
  ActionCardTitleContainer,
  ActionCardsContainer,
  CallToActionContainer,
} from './ActionCards.style'
import arrowButton from '../../../assets/images/arrow-right.svg'
import widget1BG from '../../../assets/images/widget-1.svg'
import widget2BG from '../../../assets/images/widget-2.svg'
import widget3BG from '../../../assets/images/widget-3.svg'
import widgetHeader1BG from '../../../assets/images/widget-header-1.svg'
import widgetHeader2BG from '../../../assets/images/widget-header-2.svg'
import widgetHeader3BG from '../../../assets/images/widget-header-3.svg'
import { searchActionReducer } from '../../../store/search/searchSlice'

const ActionCard = ({ card, index }) => {
  return (
    <ActionCardContainer xs="4">
      <Link to={card?.redirect} onClick={card?.onClick}>
        <ActionCardHeaderImage
          src={index === 0 ? widgetHeader1BG : index === 1 ? widgetHeader2BG : widgetHeader3BG}
        ></ActionCardHeaderImage>
        <ActionCardBodyContainer
          style={{
            background: index === 1 ? '#00669A' : index === 2 ? '#76C1CE' : '',
          }}
        >
          <ActionCardTitleContainer>{card.title}</ActionCardTitleContainer>
          <ActionCardContentContainer>{card.content}</ActionCardContentContainer>
          <CallToActionContainer>
            {card.textButton} <img src={arrowButton} alt="" />
          </CallToActionContainer>
          <img className="wave-bg" src={index === 0 ? widget1BG : index === 1 ? widget2BG : widget3BG} alt="" />
        </ActionCardBodyContainer>
      </Link>
    </ActionCardContainer>
  )
}

const ActionCards = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setType = (payload) => dispatch(searchActionReducer.setType(payload))

  const handleRentCardOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setType('FOR_RENT')
    navigate('/search')
  }

  const handleSaleCardOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setType('FOR_SALE')
    navigate('/search')
  }

  const cardsData = [
    {
      title: 'Rent An Apartment or Condo',
      content: 'Always get the best deal by focusing your search to neighborhoods you love.',
      textButton: 'Search Rentals',
      redirect: '/search',
      onClick: handleRentCardOnClick,
    },
    {
      title: 'Buy A Condo',
      content: 'Find your dream pad by searching specific buildings to stay up to date on the latest availability.',
      textButton: 'Search Sales',
      redirect: '/search',
      onClick: handleSaleCardOnClick,
    },
    {
      title: 'Promote Your Listings',
      content:
        'Be a part of the best place to apartment hunt and reach a more focused audience with highly tailored searches.',
      textButton: 'Advertise with Us',
      redirect: '/search',
    },
  ]

  const actionCards = cardsData?.map((card, index) => <ActionCard key={index} index={index} card={card} />)

  return (
    <ActionCardsContainer>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          gap: 35,
        }}
      >
        {actionCards}
      </Grid>
    </ActionCardsContainer>
  )
}

export default ActionCards

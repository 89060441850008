import React from 'react'

import AmenitiesFilter from './AmenitiesFilter'
import BedsFilter from './BedsFilter'
import { FilterWrapper } from './Filters.style'
import MoreFilter from './MoreFilter'
import NeighborhoodsFilter from './NeighborhoodsFilter'
import PriceFilter from './PriceFilter'
import SaveSearchButton from './SaveSearchButton'
import TypeFilter from './TypeFilter'

const Filters = ({ refetchListings, savedSearchesResponseIsLoading }) => {
  return (
    <FilterWrapper>
      <TypeFilter refetchListings={refetchListings} />
      <NeighborhoodsFilter refetchListings={refetchListings} />
      <BedsFilter refetchListings={refetchListings} />
      <PriceFilter refetchListings={refetchListings} />
      <AmenitiesFilter refetchListings={refetchListings} />
      <MoreFilter refetchListings={refetchListings} />
      <SaveSearchButton isLoading={savedSearchesResponseIsLoading} />
    </FilterWrapper>
  )
}

export default Filters

import { useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import _debounce from 'awesome-debounce-promise'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Button,
  Divider,
  FormContainer,
  GoogleButton,
  MessageContainer,
  RegisterContainer,
  StyledInput,
  TextContainer,
} from './Register.style'
import {
  register,
  login,
  checkEmail,
  checkPassword,
  getUserByUuid,
  loginAsSocial,
} from '../../../../services/auth.service'
import { authActionReducer } from '../../../../store/auth/authSlice'
import { modalsActionReducer } from '../../../../store/modals/modalsSlice'
import GoogleIcon from '../GoogleIcon'

const Register = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const closeAuthModal = () => dispatch(modalsActionReducer?.setAuthModalIsOpen(false))
  const setUser = (payload) => dispatch(authActionReducer?.setUser(payload))

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [authResponseIsLoading, setAuthResponseIsLoading] = useState(false)

  const emailResponseMessages = {
    ok: null,
    'invalid email': 'A valid email address is required',
    'email exists': 'An account with this email already exists.',
  }

  const passwordResponseMessages = {
    'This password is too short. It must contain at least 8 characters.':
      'Passwords must be at least 8 characters long.',
    'Passwords do not match.': 'Passwords do not match.',
    'This password is too common.': 'This password is too common.',
  }

  const validData = !!email && !!password1 && !!password2 && !emailError && !passwordError && !authResponseIsLoading
  const submitButtonIsEnabled = validData

  const validateEmail = _debounce(async (value) => {
    const response = await checkEmail(value)
    setEmailError(emailResponseMessages[response])
  }, 500)

  const validatePassword = _debounce(async (value1, value2) => {
    const response = await checkPassword({ password1: value1, password2: value2 })
    if (response === 'ok') return setPasswordError(null)
    const data = JSON.parse(response)
    const errorMessage = data?.length ? data[0] : null
    setPasswordError(passwordResponseMessages[errorMessage])
  })

  const handleEmailOnChange = (event) => {
    const value = event?.target?.value
    setEmail(value)
    validateEmail(value)
  }

  const handlePassword1OnChange = (event) => {
    const value = event?.target?.value
    setPassword1(value)
    validatePassword(value, password2)
  }

  const handlePassword2OnChange = (event) => {
    const value = event?.target?.value
    setPassword2(value)
    validatePassword(password1, value)
  }

  const handleRealtorsRegisterOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    closeAuthModal()
    navigate('/realtors/register')
  }

  const handleError = () => {
    toast.error('An unexpected error occurred.')
    setAuthResponseIsLoading(false)
  }

  const handleRegiseterOnClick = () => {
    setAuthResponseIsLoading(true)
    register(email, password1).then(
      () => {
        login(email, password1).then(
          (response) => {
            closeAuthModal()
            setUser(response)
            setAuthResponseIsLoading(false)
            toast.info('Registration successful!')
          },
          (error) => handleError(error),
        )
      },
      (error) => handleError(error),
    )
  }

  const handleRegisterWithGoogleOnClick = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setAuthResponseIsLoading(true)
      try {
        const data = await loginAsSocial({
          provider: 'google-oauth2',
          code: codeResponse.code,
          redirect_uri: 'postmessage',
        })
        if (data && data?.token) {
          const rsData = await getUserByUuid(data?.uuid, {
            headers: {
              authorization: 'Token ' + data?.token,
            },
          })
          const userData = {
            token: data?.token,
            user: rsData,
          }
          localStorage.setItem('user', JSON.stringify(userData))
          closeAuthModal()
          setUser(userData)
          setAuthResponseIsLoading(false)
          toast.info('Sign in successful!')
        }
      } catch (error) {
        handleError(error)
      }
    },
    flow: 'auth-code',
  })

  return (
    <RegisterContainer>
      <TextContainer>Take full advantage of OceanPads's features</TextContainer>
      <FormContainer>
        <StyledInput
          value={email}
          onChange={handleEmailOnChange}
          isError={emailError}
          placeholder="Email"
          type="email"
          autoComplete="username"
          id="email"
        />
        <MessageContainer isError={emailError}>{emailError}</MessageContainer>
        <StyledInput
          value={password1}
          onChange={handlePassword1OnChange}
          isError={passwordError}
          placeholder="Password"
          type="password"
          autoComplete="new-password"
          id="password"
        />
        <MessageContainer isError={passwordError}>{passwordError}</MessageContainer>
        <StyledInput
          value={password2}
          onChange={handlePassword2OnChange}
          isError={passwordError}
          placeholder="Confirm Password"
          type="password"
          autoComplete="new-password"
          id="confirm-password"
        />
      </FormContainer>
      <TextContainer>
        Real estate professional? Register{' '}
        <Link to="/realtors/register" onClick={handleRealtorsRegisterOnClick}>
          here
        </Link>
      </TextContainer>
      <Button onClick={handleRegiseterOnClick} disabled={!submitButtonIsEnabled}>
        Register
      </Button>
      <Divider>
        <span>or</span>
      </Divider>
      <GoogleButton disabled={authResponseIsLoading} onClick={handleRegisterWithGoogleOnClick}>
        <GoogleIcon />
        <span>Continue with Google</span>
      </GoogleButton>
      <TextContainer>
        I accept OceanPads's <a href="/terms">Terms of Use</a>
      </TextContainer>
    </RegisterContainer>
  )
}

export default Register

import { BackgroundContainer, CloseIconContainer, ModalContainer } from './Modal.style'

export const CloseIcon = ({ onClick }) => (
  <CloseIconContainer
    onClick={onClick}
    height="25"
    width="25"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    className="css-tj5bde-Svg"
  >
    <path
      fill="#C7C7C7"
      d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
    ></path>
  </CloseIconContainer>
)

const Modal = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null // Render nothing if the modal is not open

  const handleBackgroundClick = (e) => {
    // Prevent closing the modal when clicking inside the ModalContainer
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <BackgroundContainer isOpen={isOpen} onClick={handleBackgroundClick}>
      <ModalContainer>
        <CloseIcon onClick={onClose} />
        {children}
      </ModalContainer>
    </BackgroundContainer>
  )
}

export default Modal

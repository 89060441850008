import { useRef, useState } from 'react'

import useAxios from 'axios-hooks'
import { Braintree, HostedField } from 'react-braintree-fields'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import {
  Button,
  ButtonsContainer,
  CancelButton,
  CardTypeBadge,
  CloseButton,
  ContentContainer,
  PaymentContainer,
  HeaderContainer,
  RowContainer,
  SubTitle,
  TitleContainer,
  ErrorMessage,
} from './Payment.style'
import CLOSE_ICON from '../../../assets/images/close.svg'
import { REALTOR_BRAINTREE_PAYMENT_METHODS, REALTOR_BRAINTREE_TOKEN } from '../../../constants/api'
import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import { promotionsActionReducer } from '../../../store/promotions/promotionsSlice'

const Payment = () => {
  const containerRef = useRef()
  const numberRef = useRef()
  const cardholderNameRef = useRef()
  const cvvRef = useRef()

  const dispatch = useDispatch()

  const setRandomReloadParam = () => dispatch(promotionsActionReducer.setRandomReloadParam(Math.random()))
  const closePaymentModal = () => dispatch(modalsActionReducer?.setPaymentModalIsOpen(false))

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [cardType, setCardType] = useState('')
  const [codeType, setCodeType] = useState('CVV')
  const [tokenize, setTokenize] = useState(null)

  const [numberIsValid, setNumberIsValid] = useState(false)
  const [cardholderNameIsValid, setCardholderNameIsValid] = useState(false)
  const [expirationDateIsValid, setExpirationDateIsValid] = useState(false)
  const [cvvIsValid, setCvvIsValid] = useState(false)

  const [numberIsEmpty, setNumberIsEmpty] = useState(true)
  const [cardholderNameIsEmpty, setCardholderNameIsEmpty] = useState(true)
  const [expirationDateIsEmpty, setExpirationDateIsEmpty] = useState(true)
  const [cvvIsEmpty, setCvvIsEmpty] = useState(true)

  const [{ data: token }] = useAxios(REALTOR_BRAINTREE_TOKEN)
  const [{ loading: createPaymentMethodIsLoading }, createPaymentMethod] = useAxios(
    {
      url: REALTOR_BRAINTREE_PAYMENT_METHODS,
      method: 'POST',
    },
    { manual: true },
  )

  const isLoading = !isAuthorized

  const allFieldsValid = numberIsValid && cardholderNameIsValid && expirationDateIsValid && cvvIsValid
  const confirmButtonIsEnabled = !isLoading && !createPaymentMethodIsLoading && allFieldsValid
  const cancelButtonIsEnabled = !createPaymentMethodIsLoading

  const onAuthorizationSuccess = () => {
    numberRef?.current?.focus()
    setIsAuthorized(true)
  }

  const handleNumberValidityChange = (response) => setNumberIsValid(response?.isPotentiallyValid)
  const handleCardholderNameValidityChange = (response) => setCardholderNameIsValid(response?.isPotentiallyValid)
  const handleExpirationDateValidityChange = (response) => setExpirationDateIsValid(response?.isPotentiallyValid)
  const handleCvvValidityChange = (response) => setCvvIsValid(response?.isPotentiallyValid)

  const handleNumberOnEmpty = (response) => setNumberIsEmpty(response?.isEmpty)
  const handleCardholderNameOnEmpty = (response) => setCardholderNameIsEmpty(response?.isEmpty)
  const handleExpirationDateOnEmpty = (response) => setExpirationDateIsEmpty(response?.isEmpty)
  const handleCvvOnEmpty = (response) => setCvvIsEmpty(response?.isEmpty)

  const handleNumberOnNotEmpty = (response) => setNumberIsEmpty(response?.isEmpty)
  const handleCardholderNameOnNotEmpty = (response) => setCardholderNameIsEmpty(response?.isEmpty)
  const handleExpirationDateOnNotEmpty = (response) => setExpirationDateIsEmpty(response?.isEmpty)
  const handleCvvOnNotEmpty = (response) => setCvvIsEmpty(response?.isEmpty)

  const numberError = !numberIsEmpty && !numberIsValid ? 'Invalid card number' : ' '
  const cardholderNameError = !cardholderNameIsEmpty && !cardholderNameIsValid ? 'Invalid cardholder name' : ' '
  const expirationDateError = !expirationDateIsEmpty && !expirationDateIsValid ? 'Invalid expiration date' : ' '
  const cvvError = !cvvIsEmpty && !cvvIsValid ? 'Invalid CVV' : ' '

  const onCardTypeChange = ({ cards }) => {
    if (!cards?.length || cards?.length === 12) {
      setCardType('')
      setCodeType('CVV')
      return
    }
    const [card] = cards
    setCardType(card.niceType)
    setCodeType(card?.code?.name || 'CVV')
    cvvRef?.current?.setPlaceholder(card?.code?.name || 'CVV')
  }

  const handleError = (error) => {
    toast.error('Something went wrong. Contact us at support@oceanpads.com for assistance.')
    console.log(error)
  }

  const handleSubmitOnClick = () => {
    tokenize()
      .then((response) => {
        createPaymentMethod({
          data: {
            paymentMethodNonce: response?.nonce,
            ...response?.details,
          },
        })
          .then(() => {
            toast.success('Payment method added successfully.')
            setRandomReloadParam()
            closePaymentModal()
          })
          .catch(handleError)
      })
      .catch(handleError)
  }

  return (
    <PaymentContainer ref={containerRef}>
      <HeaderContainer>
        <TitleContainer>Add a Payment Method</TitleContainer>
        <CloseButton onClick={closePaymentModal}>
          <img src={CLOSE_ICON} alt="close" />
        </CloseButton>
      </HeaderContainer>
      <ContentContainer>
        <Braintree
          className="braintree-wrapper"
          authorization={token}
          onAuthorizationSuccess={onAuthorizationSuccess}
          onCardTypeChange={onCardTypeChange}
          getTokenRef={(ref) => setTokenize(() => ref)}
          styles={{
            input: {
              'font-family': `"Source Sans Pro", Helvetica, Arial, Geneva, sans-serif`,
              'font-size': '14px',
              '.error': {
                border: 'red',
                background: 'black',
              },
            },
          }}
        >
          <SubTitle>Card Number</SubTitle>
          <RowContainer>
            <HostedField
              type="number"
              onValidityChange={handleNumberValidityChange}
              onEmpty={handleNumberOnEmpty}
              onNotEmpty={handleNumberOnNotEmpty}
              placeholder="Number on Card"
              ref={numberRef}
            />
            <CardTypeBadge>{cardType}</CardTypeBadge>
          </RowContainer>
          <ErrorMessage>{numberError}</ErrorMessage>
          <SubTitle>Card Name</SubTitle>
          <HostedField
            type="cardholderName"
            onValidityChange={handleCardholderNameValidityChange}
            onEmpty={handleCardholderNameOnEmpty}
            onNotEmpty={handleCardholderNameOnNotEmpty}
            placeholder="Name on Card"
            ref={cardholderNameRef}
          />
          <ErrorMessage>{cardholderNameError}</ErrorMessage>
          <RowContainer>
            <div>
              <SubTitle>Expiration Date</SubTitle>
              <HostedField
                type="expirationDate"
                onValidityChange={handleExpirationDateValidityChange}
                onEmpty={handleExpirationDateOnEmpty}
                onNotEmpty={handleExpirationDateOnNotEmpty}
                placeholder="MM/YYYY"
              />
              <ErrorMessage>{expirationDateError}</ErrorMessage>
            </div>
            <div>
              <SubTitle>{codeType}</SubTitle>
              <HostedField
                onValidityChange={handleCvvValidityChange}
                onEmpty={handleCvvOnEmpty}
                onNotEmpty={handleCvvOnNotEmpty}
                type="cvv"
                placeholder={codeType}
                ref={cvvRef}
              />
              <ErrorMessage>{cvvError}</ErrorMessage>
            </div>
          </RowContainer>
        </Braintree>
        <ButtonsContainer>
          <Button disabled={!confirmButtonIsEnabled} onClick={handleSubmitOnClick}>
            Submit
          </Button>
          <CancelButton disabled={!cancelButtonIsEnabled} onClick={closePaymentModal}>
            Cancel
          </CancelButton>
        </ButtonsContainer>
      </ContentContainer>
    </PaymentContainer>
  )
}

export default Payment

import { Tooltip } from 'react-leaflet'
import styled from 'styled-components'

export const StyledTooltip = styled(Tooltip).attrs({
  className: 'StyledTooltip',
})`
  width: auto;
  border-radius: 5px;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
`

export const WrapInformation = styled.div`
  display: flex;
  flex-direction: column;
  h5 {
    color: #00669a;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h6 {
    color: #000;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import THEME from '../../../constants/themes'

export const NavbarItem = styled.div.attrs({
  className: 'NavbarItem',
})`
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  color: white;
  &:hover {
    color: #ccc;
  }
`

export const StyledLink = styled(Link).attrs({
  className: 'StyledLink',
})`
  color: inherit;
`

export const SubHeaderContainer = styled.div.attrs({
  className: 'SubHeaderContainer',
})`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 15px 80px;
  height: 70px;
  position: fixed;
  z-index: 200;
  top: 70px;
  left: 0px;
  width: 100vw;
  background: ${THEME.mediumBlue};
  color: #fff;
`

export const SubHeaderContentContainer = styled.div.attrs({
  className: 'SubHeaderContentContainer',
})`
  display: flex;
  align-items: center;
  gap: 40px;
`

import React, { Suspense } from 'react'

import Skeleton from '@mui/material/Skeleton'
import { StyledEngineProvider } from '@mui/material/styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import ReactGA from 'react-ga4'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { BrowserRouter } from 'react-router-dom'

import OceanPads from './components'
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, GOOGLE_CLIENT_ID } from './constants/env.const'
import './fonts/fonts.css'
import { BuildingProvider } from './context/BuildingContext'
import { ListingProvider } from './context/ListingContext'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // Performance Monitoring
} else {
  Sentry.init({
    dsn: 'https://b360e95cdf73b71bf85d6bdafd77de3a@o4506513758748672.ingest.sentry.io/4506513760780288',
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        // hint.xhr is a whole XHR object that you can use to modify breadcrumb
      }
      return breadcrumb
    },
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
    ],
    beforeSend(e) {
      if (e?.exception.values[0].value === 'Network Error' || e.exception.values[0].value === 'canceled') {
        return null
      }
      return e
    },
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.oceanpads\.com/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          window.location.origin,
          'https://oceanpads.com/',
          'api.oceanpads.com',
          /^https:\/\/oceanpads\.com/,
          /^https:\/\/api\.oceanpads\.com/,
        ],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// request interceptor to add token to request headers
axios.interceptors.request.use(
  async (config) => {
    const userStr = localStorage.getItem('user')

    let user = null
    if (userStr) user = JSON.parse(userStr)

    if (user && user.token) {
      config.headers = { Authorization: 'Token ' + user.token }
    } else {
    }

    return config
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    Sentry.captureException(error)
    return Promise.reject(error)
  },
)

ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)

const App = () => {
  return (
    <Suspense fallback={<Skeleton />}>
      <StyledEngineProvider injectFirst>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <ListingProvider>
            <BuildingProvider>
              <BrowserRouter basename={'/'}>
                <OceanPads />
              </BrowserRouter>
            </BuildingProvider>
          </ListingProvider>
        </GoogleOAuthProvider>
      </StyledEngineProvider>
    </Suspense>
  )
}

export default App

import { groupAndSortAddresses } from '../../utilities'

function transformToSchemaOrg(building) {
  const addresses = groupAndSortAddresses(building?.addresses)

  const addressStrings = addresses?.map((address, index) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]

    return isRange && address.addresses?.length === 2
      ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
      : isRange
      ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
      : firstAddress?.value
  })

  const type = building?.type === 'COMMUNITY' ? 'GatedResidenceCommunity' : 'ApartmentComplex'
  const address = addressStrings?.join(';')
  const buildingUrl = `https://www.oceanpads.com${building?.url}`
  const imageUrl = building?.medias?.[0]?.publicImageUrl
  const lat = building?.coordinates?.[1]
  const lng = building?.coordinates?.[0]

  const schemaOrgJsonLd = {
    '@context': 'http://schema.org',
    '@type': type,
    name: building?.name,
    image: imageUrl,
    url: buildingUrl,
    address: address,
    geo: {
      '@type': 'GeoCoordinates',
      name: building?.name,
      image: imageUrl,
      url: buildingUrl,
      address: address,
      latitude: lat,
      longitude: lng,
    },
  }

  return schemaOrgJsonLd
}

export default transformToSchemaOrg

import { useState } from 'react'

import { HeroImagesContainer, SVGImg } from './HeroImages.style'
import skylineCloudsLight1 from '../../../assets/images/hero/skyline-clouds-light-1.svg'
import skylineCloudsLight2 from '../../../assets/images/hero/skyline-clouds-light-2.svg'
import skylinePiece1 from '../../../assets/images/hero/skyline-piece-1.svg'
import skylinePiece2 from '../../../assets/images/hero/skyline-piece-2.svg'
import skylinePiece3 from '../../../assets/images/hero/skyline-piece-3.svg'
import skylinePiece4 from '../../../assets/images/hero/skyline-piece-4.svg'
import skylineWater from '../../../assets/images/hero/skyline-water.svg'
import { HERO_MOBILE } from '../../../constants/image.const'
import './hero.css'

const HeroImages = () => {
  const [sky1Loaded, setSky1Loaded] = useState(false)
  const [sky2Loaded, setSky2Loaded] = useState(false)
  const [left1Loaded, setLeft1Loaded] = useState(false)
  const [left2Loaded, setLeft2Loaded] = useState(false)
  const [right1Loaded, setRight1Loaded] = useState(false)
  const [right2Loaded, setRight2Loaded] = useState(false)
  const [waterLoaded, setWaterLoaded] = useState(false)

  const allImagesLoaded =
    sky1Loaded && sky2Loaded && left1Loaded && left2Loaded && right1Loaded && right2Loaded && waterLoaded

  return (
    <>
      <HeroImagesContainer className="hidden-mobile">
        <SVGImg
          src={skylineCloudsLight1}
          alt="hero-clouds"
          className={allImagesLoaded && 'bobble-1'}
          loading="eager"
          onLoad={() => setSky1Loaded(true)}
        />
        <SVGImg
          src={skylineCloudsLight2}
          alt="hero-clouds"
          className={allImagesLoaded && 'bobble-2'}
          loading="eager"
          onLoad={() => setSky2Loaded(true)}
        />
        <SVGImg
          src={skylinePiece4}
          isAnimated
          strength={300}
          direction="right"
          alt="hero-4"
          className={allImagesLoaded && 'slide-in-right-1'}
          loading="eager"
          onLoad={() => setRight1Loaded(true)}
          style={{ display: allImagesLoaded ? 'block' : 'none' }}
        />
        <SVGImg
          src={skylinePiece3}
          isAnimated
          strength={100}
          direction="right"
          alt="hero-3"
          className={allImagesLoaded && 'slide-in-right-2'}
          loading="eager"
          onLoad={() => setRight2Loaded(true)}
          style={{ display: allImagesLoaded ? 'block' : 'none' }}
        />
        <SVGImg
          src={skylinePiece2}
          isAnimated
          strength={100}
          direction="left"
          alt="hero-2"
          className={allImagesLoaded && 'slide-in-left-2'}
          loading="eager"
          onLoad={() => setLeft2Loaded(true)}
          style={{ display: allImagesLoaded ? 'block' : 'none' }}
        />
        <SVGImg
          src={skylinePiece1}
          isAnimated
          strength={300}
          direction="left"
          alt="hero-1"
          className={allImagesLoaded && 'slide-in-left-1'}
          loading="eager"
          onLoad={() => setLeft1Loaded(true)}
          style={{ display: allImagesLoaded ? 'block' : 'none' }}
        />
        <SVGImg
          src={skylineWater}
          alt="hero-water"
          className={allImagesLoaded}
          loading="eager"
          onLoad={() => setWaterLoaded(true)}
        />
      </HeroImagesContainer>
      <HeroImagesContainer className="hidden-desktop">
        <SVGImg src={HERO_MOBILE} alt="hero" loading="eager" />
      </HeroImagesContainer>
    </>
  )
}
export default HeroImages

import { useEffect, useState } from 'react'

import moment from 'moment/moment'
import ReactGA from 'react-ga4'

import DetailsTable from './DetailsTable'
import { UnitTableContainer, ControllerContainer, RadioButton } from './UnitsTable.style'
import clickEvents from '../../../../../services/analytics.service'

const UnitTable = ({ building }) => {
  const tabs =
    building?.type === 'APARTMENT'
      ? ['For Rent', 'All Units', 'Past Rentals']
      : ['For Rent', 'For Sale', 'All Units', 'Past Rentals', 'Past Sales']

  const [activeTab, setActiveTab] = useState(tabs[0])
  const [sortBy, setSortBy] = useState('')

  const priceFormatter = () =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })

  const ppsfFormatter = (type) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: type === 'FOR_RENT' ? 2 : 0,
      // minimumSignificantDigits: 2,
      // maximumSignificantDigits: 2,
      // notation: windowWidth <= 768 ? 'compact' : 'standard',
    })

  const toTitleCase = (str) => {
    if (!str) return ''
    return str
      .toLowerCase()
      .replace(/-/g, ' ')
      .replace(/(?:^|\s)\w/g, function (match) {
        return match.toUpperCase()
      })
  }

  const toDate = (str) => {
    if (!str) return ''
    const dateFormat = window.innerWidth <= 992 ? 'M/D/YYYY' : 'MMM Do, YYYY'
    return moment(str).format(dateFormat)
  }

  const sortingMethods = {
    baths: (a, b) => a.unit?.numberOfBathrooms - b.unit?.numberOfBathrooms,
    '-baths': (a, b) => b.unit?.numberOfBathrooms - a.unit?.numberOfBathrooms,
    beds: (a, b) => a.unit?.numberOfBedrooms - b.unit?.numberOfBedrooms,
    '-beds': (a, b) => b.unit?.numberOfBedrooms - a.unit?.numberOfBedrooms,
    close: (a, b) => a.closeDate?.localeCompare(b.closeDate),
    '-close': (a, b) => b.closeDate?.localeCompare(a.closeDate),
    ppsf: (a, b) => a.pricePerSquareFoot - b.pricePerSquareFoot,
    '-ppsf': (a, b) => b.pricePerSquareFoot - a.pricePerSquareFoot,
    price: (a, b) => a.price - b.price,
    '-price': (a, b) => b.price - a.price,
    size: (a, b) => a.unit?.squareFootage - b.unit?.squareFootage,
    '-size': (a, b) => b.unit?.squareFootage - a.unit?.squareFootage,
    tower: (a, b) => a.unit?.tower?.localeCompare(b.unit?.tower),
    '-tower': (a, b) => b.unit?.tower?.localeCompare(a.unit?.tower),
    unit: (a, b) => a.unit?.number?.padStart(20, '0')?.localeCompare(b.unit?.number?.padStart(20, '0')),
    '-unit': (a, b) => b.unit?.number?.padStart(20, '0')?.localeCompare(a.unit?.number?.padStart(20, '0')),
  }

  const unitSortingMethods = {
    baths: (a, b) => a.numberOfBathrooms - b.numberOfBathrooms,
    '-baths': (a, b) => b.numberOfBathrooms - a.numberOfBathrooms,
    beds: (a, b) => a.numberOfBedrooms - b.numberOfBedrooms,
    '-beds': (a, b) => b.numberOfBedrooms - a.numberOfBedrooms,
    size: (a, b) => a.squareFootage - b.squareFootage,
    '-size': (a, b) => b.squareFootage - a.squareFootage,
    tower: (a, b) => a.tower?.localeCompare(b.tower),
    '-tower': (a, b) => b.tower?.localeCompare(a.tower),
    unit: (a, b) => a.number?.padStart(20, '0')?.localeCompare(b.number?.padStart(20, '0')),
    '-unit': (a, b) => b.number?.padStart(20, '0')?.localeCompare(a.number?.padStart(20, '0')),
  }

  const sortedListingsData = building?.listings?.sort(sortingMethods[sortBy || 'unit'])
  const sortedUnitsData = building?.units?.sort(unitSortingMethods[sortBy || 'unit'])

  const listingData = sortedListingsData?.map((listing) => ({
    tower: toTitleCase(listing?.unit?.tower),
    unit: listing?.unit?.number,
    price: priceFormatter().format(listing?.price),
    beds: listing?.unit?.numberOfBedrooms || '0',
    baths: listing?.unit?.numberOfBathrooms,
    size: listing?.unit?.squareFootage,
    ppsf: ppsfFormatter(listing?.type).format(listing?.pricePerSquareFoot),
    close: toDate(listing?.closeDate),
    type: listing?.type,
    status: listing?.status,
    url: listing?.url,
    uuid: listing?.uuid,
  }))

  const allUnitsData = sortedUnitsData?.map((unit) => ({
    tower: toTitleCase(unit?.tower),
    unit: unit?.number,
    beds: unit?.numberOfBedrooms || '0',
    baths: unit?.numberOfBathrooms,
    size: unit?.squareFootage,
  }))

  const data = {
    'For Rent': listingData?.filter((listing) => listing.type === 'FOR_RENT' && listing.status === 'OPEN'),
    'For Sale': listingData?.filter((listing) => listing.type === 'FOR_SALE' && listing.status === 'OPEN'),
    'All Units': allUnitsData,
    'Past Rentals': listingData?.filter((listing) => listing.type === 'FOR_RENT' && listing.status === 'CLOSED'),
    'Past Sales': listingData?.filter((listing) => listing.type === 'FOR_SALE' && listing.status === 'CLOSED'),
  }

  const showTowers = !!data[activeTab]?.filter((row) => row?.tower)?.length
  const showPrice = activeTab !== 'All Units'
  const showCloseDate = activeTab?.includes('Past')

  const columns = [
    { value: 'tower', label: 'Tower', visible: showTowers },
    { value: 'unit', label: 'Unit', visible: true },
    { value: 'price', label: 'Price', visible: showPrice },
    { value: 'beds', label: 'Beds', visible: true },
    { value: 'baths', label: 'Baths', visible: true },
    { value: 'size', label: 'ft²', visible: true },
    { value: 'ppsf', label: 'Price/ft²', visible: showPrice },
    { value: 'close', label: 'Close Date', visible: showCloseDate },
    { value: 'url', label: null, visible: false },
  ]

  const radioButtons = tabs?.map((tab) => {
    const selected = tab === activeTab
    const handleRadioButtonOnClick = () => {
      setActiveTab(tab)
      ReactGA.event({
        category: 'action',
        action: `building-page-${tab.toLowerCase().replace(' ', '-')}-tab-clicked`,
      })
      clickEvents[tab.toUpperCase().replace(' ', '_') + '_TAB']()
    }
    return (
      <RadioButton key={tab} selected={selected} onClick={handleRadioButtonOnClick}>
        {tab}
      </RadioButton>
    )
  })

  useEffect(() => {
    setActiveTab(tabs[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building?.type])

  return (
    <UnitTableContainer>
      <ControllerContainer>{radioButtons}</ControllerContainer>
      <DetailsTable
        activeTab={activeTab}
        columns={columns}
        data={data[activeTab]}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </UnitTableContainer>
  )
}

export default UnitTable

import styled from 'styled-components'

import THEME from '../../../../constants/themes'

export const AmenitiesCheckboxesContainer = styled.div.attrs({
  className: 'AmenitiesCheckboxesContainer',
})`
  display: flex;
  flex-direction: column;
`

export const AmenitiesDropdownContainer = styled.div.attrs({
  className: 'AmenitiesDropdownContainer',
})(
  ({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    z-index: 185 !important;
    border-radius: 0 0 10px 10px !important;
    margin-top: -10px !important;
    width: calc(100% + 1px);
    max-width: calc(100% + 1px);
    padding: 0.5em;
    left: -1px;
    background: ${THEME.backgroundGray} !important;
    border: 1px solid rgb(230, 230, 230) !important;
    border-top: none !important;
    position: absolute;
    top: 75px;  
  `,
)

export const AmenitiesFilterContainer = styled.div.attrs({
  className: 'AmenitiesFilterContainer',
})(
  ({ isOpen }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    min-width: 182px;
    cursor: pointer;
    background-color: ${isOpen ? '#fafafa' : 'white'};
    position: relative;
    &:hover {
      background-color: #fafafa;
    }    
    &:after {
      content: '';
      height: 80%;
      width: 1px;
      border-right: 1px solid rgb(230, 230, 230);
    }
    @media (max-width: 1240px) {
      min-width: 178px;
    }
  `,
)

export const AmenitiesTriggerContainer = styled.div.attrs({
  className: 'AmenitiesTriggerContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 1em;
  @media (max-width: 1240px) {
    padding: 0 0.5em;
  }
`

export const AmenityOption = styled.div.attrs({
  className: 'AmenityOption',
})(
  ({ isSelected }) => `
    display: flex;
    gap: 0.5em;
    padding: 3px;
    align-items: center;
    background: ${isSelected ? 'lightblue' : THEME.backgroundGray};
    &:hover {
      background: ${isSelected ? THEME.lightBlue : 'white'};
    }
  `,
)

export const AmenityOptionCheckbox = styled.input.attrs({
  className: 'AmenityOptionCheckbox',
  type: 'checkbox',
})`
  &:checked {
    accent-color: ${THEME.mediumBlue};
  }
`

export const AmenityOptionLabel = styled.div.attrs({
  className: 'AmenityOptionLabel',
})`
  font-size: 13px;
`

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 5px;
  margin-top: 5px;
  flex-direction: column;
`

export const DoneButton = styled.div.attrs({
  className: 'DoneButton',
})`
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  min-height: 41px;
  width: 100%;
  background: ${THEME.mediumBlue};
  color: #fff;
  &:hover {
    background: ${THEME.darkBlue};
  }
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
)

export const IconsContainer = styled.div.attrs({
  className: 'IconsContainer',
})`
  height: 100%;
  display: flex;
  align-items: center;
`

export const ResetButton = styled.div.attrs({
  className: 'ResetButton',
})`
  display: flex;
  min-width: auto;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  min-height: 41px;
  color: ${THEME.mediumBlue};
  width: 100%;
  &:hover {
    background-color: #eee;
  }
`
